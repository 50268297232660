import React from 'react';
import { t } from '@lingui/macro';

import { DropdownSelect } from '@float/ui/deprecated';

import {
  AllocationTypeOption,
  AllocationTypeOptionValue,
} from '../../hooks/useAllocationTypeControls';

export type AllocationTypeSelectProps = {
  allocationTypeOptionSelectedValue: AllocationTypeOptionValue;
  allocationTypeOptions: AllocationTypeOption[];
  handleAllocationTypeChange: ({ value }: AllocationTypeOption) => void;
};

export const AllocationTypeSelect = (props: AllocationTypeSelectProps) => {
  const {
    allocationTypeOptionSelectedValue,
    allocationTypeOptions,
    handleAllocationTypeChange,
  } = props;

  return (
    <DropdownSelect
      appearance="text-flue"
      value={allocationTypeOptionSelectedValue}
      options={allocationTypeOptions}
      minSelectWidth={152}
      tickSelectedOption={true}
      tickRight={true}
      onChange={handleAllocationTypeChange}
      ariaLabel={t`Allocation type select`}
    />
  );
};
