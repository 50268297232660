import React, { createRef } from 'react';
import {
  CellMeasurer,
  CellMeasurerCache,
  List,
} from '@floatschedule/react-virtualized';
import * as PopoverPrimitive from '@radix-ui/react-popover';
import { flatMap, get, isEmpty, map, uniqBy } from 'lodash';
import styled from 'styled-components';

import { prevent } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { noop } from '@float/libs/utils/noop';
import { Checkbox } from '@float/ui/deprecated/Checkbox';
import EH from '@float/ui/deprecated/Earhart';
import { IconCheck } from '@float/ui/deprecated/Earhart/Icons';
import Icons from '@float/ui/deprecated/Icons';
import { FieldLabel } from '@float/ui/deprecated/Label';
import { Col, Row, Spacer } from '@float/ui/deprecated/Layout/Layout';
import { ErrorText, SecondaryText } from '@float/ui/deprecated/Text';

import {
  FieldWrapper,
  InputPrefix,
  InputSuffix,
  InputWrapper,
} from '../Input/styles';
import { VirtualSelectOptionDescription } from './components/VirtualSelectOptionDescription';
import { calculateNextIndex, Direction } from './helpers/calculateNextIndex';
import {
  ClearSelectedOptions,
  PopoverContent,
  RowHeader,
  RowHeaderAll,
  RowHeaderGroupName,
  RowIcon,
  RowIconRight,
  RowLabel,
  RowWrapper,
  StyledInputWrapper,
} from './styles';

import * as styles from './VirtualSelect.styles.css';

const ITEM_DEFAULT_HEIGHT_PX = 44;

export const KEYS = {
  backspace: 8,
  enter: 13,
  tab: 9,
  escape: 27,
  up: 38,
  down: 40,
};

const normalize = (val) => `${val}`.toLowerCase();
const preventDefault = (evt) => evt.preventDefault();

const createCellMeasurerCache = () =>
  new CellMeasurerCache({
    defaultHeight: ITEM_DEFAULT_HEIGHT_PX,
    minHeight: 17,
    maxHeight: 17,
    fixedWidth: true,
  });

const IconLeft = styled.div`
  display: flex;
  align-items: center;
  svg {
    opacity: 1;
    transform: scale(1);
    margin-left: 0px;
  }
`;

export const ListOption = (props) => {
  const { option, isHighlighted, size, role, overflowEllipse } = props;

  const isCheckedRightIcon = typeof option.checked === 'boolean';
  const rightIcon =
    option.iconRight ||
    (option.selected && <IconCheck />) ||
    (isCheckedRightIcon && (
      <Checkbox
        value={option.checked}
        onClick={(e) => {
          prevent(e);
          props.onClick?.(e, true);
        }}
      />
    ));

  return (
    <RowLabel
      role={role}
      $size={size}
      highlighted={isHighlighted}
      hasDescription={!!option.description}
      disabled={option.disabled}
      isSelected={option.selected}
      onMouseEnter={() => {
        props.onMouseHoverChange?.(true);
      }}
      onMouseLeave={() => {
        props.onMouseHoverChange?.(false);
      }}
      onMouseDown={(e) => props.onMouseDown?.(e)}
      onClick={(e) => props.onClick?.(e)}
      onMouseUp={(e) => props.onMouseUp?.(e)}
      onMouseMove={(e) => props.onMouseMove?.(e)}
    >
      <Col>
        <Row justifyContent="space-between">
          <Row className={styles.optionRow}>
            {option.indent && <Spacer xSize={8} />}
            {option.icon && (
              <>
                <IconLeft>{option.icon}</IconLeft>
                <Spacer xSize={8} />
              </>
            )}
            <span className={overflowEllipse ? styles.optionLabelNoWrap : ''}>
              {option.label}
            </span>
          </Row>
          {rightIcon && (
            <>
              <Spacer axis="x" xSize={8} />
              <RowIconRight>{rightIcon}</RowIconRight>
            </>
          )}
        </Row>
        {(option.description || option.isLoadingDescription) && (
          <VirtualSelectOptionDescription
            className={styles.virtualSelect({
              icon: option.icon ? 'left' : 'default',
            })}
            description={option.description}
            isLoading={option.isLoadingDescription}
          />
        )}
      </Col>
    </RowLabel>
  );
};

class VirtualSelect extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.fromOnClearClick = false;

    const options = this.deriveOptions(props);

    this.state = {
      highlightedIndex: -1,
      inputVal: props.value || '',
      isSearching: false,
      isOpen: false,
      options,
    };

    Object.assign(this.state, this.filterOptions());
  }

  // ---------------------------------------------------------------------------
  // !!! Helpers ---------------------------------------------------------------
  // ---------------------------------------------------------------------------

  deriveOptions = (props = this.props) => {
    if (props.options && props.groupedOptions) {
      throw Error('Provide either options OR groupedOptions');
    }

    let options;
    if (props.options) {
      options = map(props.options, (o) => ({
        ...o,
        normalizedLabel: normalize(o.selectedLabel || o.label),
      }));
    } else {
      options = flatMap(props.groupedOptions, (go) => {
        return go.options.map((o) => ({
          ...o,
          groupName: go.name,
          groupValue: go.value,
          normalizedLabel: normalize(o.selectedLabel || o.label),
          normalizedGroupName: normalize(go.name),
        }));
      });
    }

    const renderableOpts = options.filter(
      (o) => !!o.render || typeof o.label === 'string',
    );

    const deduped = uniqBy(renderableOpts, 'value');
    if (deduped.length !== renderableOpts.length) {
      // TODO: Clean this up. This is a temporary fix for https://app.asana.com/0/0/1003092623007158/f
      // throw Error('Found duplicate values in options');
      console.error('Found duplicate values in options');
    }

    return renderableOpts;
  };

  isTypeToCreate = () =>
    this.props.creatable &&
    this.props.hideCreateOption &&
    this.state.filteredOptions.length === 1 &&
    this.state.highlightedIndex === 0;

  optionContainsText = (o, text) =>
    o.normalizedLabel.includes(text) ||
    (o.normalizedGroupName && String(o.normalizedGroupName).includes(text));

  optionStartsWithText = (o, text) =>
    o.normalizedLabel.startsWith(text) ||
    (o.normalizedGroupName && String(o.normalizedGroupName).startsWith(text));

  optionExists = () => true;

  getTrimmedInputVal = () => {
    const { inputVal } = this.state;
    if (typeof inputVal === 'string') {
      return inputVal.trim();
    }

    return inputVal ?? '';
  };

  filterOptions = (state = this.state, openingDropdown = false) => {
    const disallowedOptions = this.props.disallowedOptions ?? [];
    const normalizedInput = normalize(state.inputVal);
    const searchCriteria =
      this.props.filterUsingStartsWith || openingDropdown
        ? this.optionStartsWithText
        : this.optionContainsText;
    const secondaryFilter = !state.isSearching
      ? this.optionExists
      : searchCriteria;

    const filteredOptions = state.options.filter(
      (o) =>
        !disallowedOptions.some((o2) => o2.value === o.value) &&
        secondaryFilter(o, normalizedInput),
    );

    const groupStartIndices = [];
    if (this.props.groupedOptions && !isEmpty(filteredOptions)) {
      let curGroupName = filteredOptions[0].groupName;
      groupStartIndices.push(0);

      filteredOptions.forEach((o, idx) => {
        if (o.groupName !== curGroupName) {
          curGroupName = o.groupName;
          groupStartIndices.push(idx);
        }
      });
    }

    const inputVal = this.getTrimmedInputVal();
    const canCreateInput = this.props.creatable && inputVal.length;
    if (canCreateInput) {
      // Disallow creating an option exactly equal to an existing one
      const isAlreadyDefined =
        !filteredOptions.some((o) => o.normalizedLabel === normalizedInput) &&
        !disallowedOptions.some((o) => normalize(o.label) === normalizedInput);

      if (isAlreadyDefined) {
        filteredOptions.push({
          value: inputVal,
          label: inputVal,
          normalizedLabel: normalize(inputVal),
          isCreate: true,
        });
      }
    }

    const ret = {
      filteredOptions,
      groupStartIndices,
      cache: createCellMeasurerCache(),
    };

    if (openingDropdown && !this.shouldHighlightPreselectOption()) {
      return ret;
    }
    // highlight selected option if characters are typed and a match exists
    if (normalizedInput && filteredOptions.length) {
      if (state.isSearching) {
        // options already filtered, first option is a match
        ret.highlightedIndex = 0;
      } else {
        const index = filteredOptions.findIndex((o) =>
          searchCriteria(o, normalizedInput),
        );
        if (index > -1) {
          ret.highlightedIndex = index;
        }
      }
      if (ret.highlightedIndex) ret.scrollToIndex = ret.highlightedIndex;
    }

    return ret;
  };

  shouldHighlightPreselectOption = () => {
    const { options, groupedOptions } = this.props;
    const selectableOptions = options ?? groupedOptions?.[0]?.options ?? [];
    return typeof selectableOptions?.selected !== 'boolean';
  };

  getHeight = () => {
    const { visibleItems, height } = this.props;

    if (visibleItems > 0) {
      return this.props.visibleItems * ITEM_DEFAULT_HEIGHT_PX + 6 * 2;
    }

    return height;
  };

  setListRef = (ref) => {
    if (ref) {
      this.listRef = ref;
      // These next two lines fix an issue with forcedOpen
      this.setState({ cache: createCellMeasurerCache() });
      this.forceUpdate();
    }
  };

  // ---------------------------------------------------------------------------
  // !!! Mouse / Keyboard Events -----------------------------------------------
  // ---------------------------------------------------------------------------

  setInputVal = (evt) => {
    this.setState({ inputVal: evt.target.value, isSearching: true });
    if (typeof this.props.onInputChange === 'function') {
      this.props.onInputChange(evt);
    }
  };

  setHighlightedIndex = (highlightedIndex) => {
    this.setState({ highlightedIndex }, () => {
      this.listRef?.forceUpdateGrid();
    });
  };

  focusInput = () => {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  };

  onFocus = (evt) => {
    if (typeof this.props.onFocus === 'function') {
      const preventDefaultAndStopPropagation = this.props.onFocus(evt);
      if (preventDefaultAndStopPropagation) return;
    }

    if (!this.state.isOpen && !this.fromOnClearClick) {
      this.showDropdown();
    }

    if (this.props.autoSelectOnFocus && this.props.searchable) {
      setTimeout(() => {
        if (this.inputRef.current) {
          this.inputRef.current.select();
        }
      }, 0);
    }
  };

  showDropdown = () => {
    if (this.props.autoFocus && !this.hasBeenAutofocused) {
      this.hasBeenAutofocused = true;
      return;
    }

    if (this.state.isOpen) return;

    this.mouseHighlight = false;

    this.setState((ps) => {
      const highlightedIndex = this.shouldHighlightPreselectOption()
        ? ps.valueIndex
        : -1;

      const newState = {
        isOpen: true,
        highlightedIndex,
        scrollToIndex: ps.highlightedIndex,
      };

      // TODO: Discuss: do we want "not clearing existing value on focus" to be default behavior?
      if (!this.props.clearInputOnDropdownOpen) {
        const filterUpdate = this.filterOptions(ps, true);

        return {
          ...newState,
          ...filterUpdate,
          scrollToIndex: filterUpdate.highlightedIndex,
        };
      }
      newState.inputVal = '';
      return newState;
    });

    if (!this.props.searchable) {
      return;
    }

    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
    setTimeout(() => {
      if (this.inputRef.current) {
        this.inputRef.current.select();
      }
    }, 0);
  };

  hideDropdown = () => {
    if (this.state.isOpen && !this.unmounting) {
      this.setState({
        isOpen: false,
        inputVal: this.props.value || '',
        isSearching: false,
      });
    }
  };

  shouldKeepFocus() {
    return this.props.keepFocusAfterSelect && this.getRowCount() - 1 > 0;
  }

  selectHighlightedOption = (hideIfSelected = true) => {
    const highlightedIndex = this.mouseDownIndex ?? this.state.highlightedIndex;

    const selected =
      highlightedIndex > -1
        ? this.state.filteredOptions[highlightedIndex]
        : null;

    if (selected) {
      this.props.onChange(selected);

      if (hideIfSelected || !this.shouldKeepFocus()) {
        this.hideDropdown();
      }
      return true;
    }
    return false;
  };

  onClearClick = (evt) => {
    // when clearing the input, the input
    // should remain in focus and
    // the open state should be kept
    // see why here https://linear.app/float-com/issue/FT-1442/
    evt.preventDefault();
    evt.stopPropagation();

    this.fromOnClearClick = true;

    const newState = {
      inputVal: '',
      highlightedIndex: -1,
      isSearching: false,
    };

    this.setState(newState, () => {
      this.focusInput();
      this.fromOnClearClick = false;
    });

    this.props.onChange(newState.inputVal);
  };

  onInputMouseDown = (evt) => {
    if (typeof this.props.onInputMouseDown === 'function') {
      this.props.onInputMouseDown(evt);
    }
  };

  onInputBlur = (evt) => {
    if (typeof this.props.onInputBlur === 'function') {
      this.props.onInputBlur(evt);
      return;
    }
  };

  onInputKeyDown = (evt) => {
    if (typeof this.props.onInputKeyDown === 'function') {
      const preventDefaultAndStopPropagation = this.props.onInputKeyDown(evt);
      if (preventDefaultAndStopPropagation) {
        return;
      }
    }

    const { isOpen } = this.state;
    this.mouseHighlight = false;

    if (evt.keyCode === KEYS.backspace || evt.key === 'Backspace') {
      if (this.state.inputVal === '' && this.props.onEmptyBackspace) {
        this.props.onEmptyBackspace();
      }
      return;
    }

    if (evt.keyCode === KEYS.tab || evt.key === 'Tab') {
      if (isOpen) {
        if (this.selectHighlightedOption(false)) {
          if (this.props.keepFocusAfterSelect) {
            prevent(evt);
          }
        } else {
          this.hideDropdown();
        }
      }
      return;
    }

    if (evt.keyCode === KEYS.enter || evt.key === 'Enter') {
      if (isOpen) {
        if (!this.isTypeToCreate()) {
          prevent(evt);
        }
        const selected = this.selectHighlightedOption(false);

        if (selected) {
          this.props.onEnterSelect?.();
        }
      }
      if (typeof this.props.onEnter === 'function') {
        this.props.onEnter();
      }
      return;
    }

    if (evt.keyCode === KEYS.escape || evt.key === 'Escape') {
      if (isOpen) {
        prevent(evt);
        this.hideDropdown();
      }
      return;
    }

    if (evt.keyCode === KEYS.down || evt.key === 'ArrowDown') {
      evt.preventDefault();

      if (!isOpen) {
        this.showDropdown();
        return;
      }

      this.selectBelowItem();
      return;
    }

    if (evt.keyCode === KEYS.up || evt.key === 'ArrowUp') {
      evt.preventDefault();

      if (!isOpen) {
        this.showDropdown();
        return;
      }

      this.selectAboveItem();
      return;
    }

    if (!this.props.hideUnmatchedOptionsOnFilter) {
      this.setState({
        isOpen: true,
        scrollToIndex: this.state.highlightedIndex,
      });
      return;
    }

    this.setState({ highlightedIndex: -1, scrollToIndex: -1, isOpen: true });
  };

  selectAboveItem() {
    const { filteredOptions, highlightedIndex } = this.state;

    const nextIndex = calculateNextIndex(
      filteredOptions,
      highlightedIndex,
      Direction.Up,
    );

    this.setHighlightedIndex(nextIndex);
    return nextIndex;
  }

  selectBelowItem() {
    const { filteredOptions, highlightedIndex } = this.state;

    const nextIndex = calculateNextIndex(
      filteredOptions,
      highlightedIndex,
      Direction.Down,
    );

    this.setHighlightedIndex(nextIndex);

    return nextIndex;
  }

  setReferenceElement = (el) => {
    this.referenceElement = el;
  };

  resizeInput = () => {
    if (!this.props.autoSize || !this.referenceElement) {
      return;
    }

    const newWidth = this.referenceElement.offsetWidth + 8;
    const shouldGrow = this.props.maxWidth
      ? newWidth <= this.props.maxWidth
      : true;

    if (shouldGrow) {
      this.setState({ inputStyle: { width: newWidth } });
    }
  };

  scrollList = (e) => {
    if (
      this.listRef &&
      this.listRef.Grid &&
      this.listRef.Grid._scrollingContainer
    ) {
      this.listRef.Grid._scrollingContainer.scrollTop += e.deltaY;
    }
  };

  // ---------------------------------------------------------------------------
  // !!! React Lifecycle -------------------------------------------------------
  // ---------------------------------------------------------------------------

  static getDerivedStateFromProps(props, state) {
    if (!state.isOpen) {
      const { options } = state;
      const { creatable, value } = props;

      const valueIndex = options.findIndex((o) => o.value == value);
      const opt = valueIndex === -1 ? null : options[valueIndex];

      let inputVal;

      if (opt) {
        inputVal = opt.selectedLabel || opt.label;
      } else if (creatable && value) {
        inputVal = value;
      } else {
        inputVal = '';
      }

      return {
        valueIndex,
        selectedOption: opt,
        inputVal,
      };
    }

    return null;
  }

  componentDidMount() {
    if (
      this.props.nonNullable &&
      this.props.value === '' &&
      this.state.options.length
    ) {
      this.props.onChange(this.state.options[0]);
    }

    if (typeof this.props.vsRef === 'function') {
      this.props.vsRef(this);
    }

    setTimeout(this.resizeInput);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.options !== this.props.options ||
      prevProps.disallowedOptions !== this.props.disallowedOptions ||
      prevProps.groupedOptions !== this.props.groupedOptions
    ) {
      this.setState({
        options: this.deriveOptions(this.props),
      });
    }

    if (
      prevState.options !== this.state.options ||
      prevState.inputVal !== this.state.inputVal
    ) {
      this.setState((ps) => ({
        ...this.filterOptions(ps),
      }));
    }

    if (prevState.inputVal !== this.state.inputVal) {
      setTimeout(this.resizeInput);
    }

    if (this.state.isOpen !== prevState.isOpen) {
      this.props.onOpenChange(this.state.isOpen);
    }
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  // ---------------------------------------------------------------------------
  // !!! Render ----------------------------------------------------------------
  // ---------------------------------------------------------------------------

  checkCanClearSelect = () => {
    const { nonNullable, hideClearIcon, creatable, value } = this.props;
    const hasNewValue = !!(creatable && value);
    const { selectedOption } = this.state;
    if (hideClearIcon) return false;
    if (nonNullable) return false;
    if (
      (!selectedOption && !hasNewValue) ||
      (selectedOption && selectedOption.hideClearIcon)
    )
      return false;
    return true;
  };

  rowRenderer = ({ index, key, parent, style }) => {
    const { createPrefix } = this.props;
    const { highlightedIndex, filteredOptions, groupStartIndices } = this.state;

    const option = filteredOptions[index];
    if (option.isCreate && this.props.hideCreateOption) {
      return null;
    }
    const styleWithGap = {
      ...style,
      paddingTop: `${index ? this.props.gapBetweenItems : 0}px`,
    };

    const isHighlighted = (this.mouseDownIndex ?? highlightedIndex) === index;
    return (
      <CellMeasurer
        cache={this.state.cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        <RowWrapper style={styleWithGap} data-callout-id={option.calloutId}>
          {groupStartIndices.includes(index) && option.groupName && (
            <RowHeader>
              <RowHeaderGroupName>{option.groupName}</RowHeaderGroupName>
              {this.props.onSelectGroupAll && (
                <RowHeaderAll
                  onClick={() => {
                    const groupOptions = this.state.options.filter(
                      (o) => o.groupValue === option.groupValue,
                    );
                    this.props.onSelectGroupAll(groupOptions);
                  }}
                >
                  All
                </RowHeaderAll>
              )}
            </RowHeader>
          )}
          <ListOption
            role="option"
            option={{
              ...option,
              label: option.isCreate
                ? `${createPrefix} "${option.label}"`
                : option.label,
              icon:
                option.icon ||
                (option.isCreate && (
                  <RowIcon>
                    <Icons.Add />
                  </RowIcon>
                )),
            }}
            size={this.props.rowLabelSize}
            overflowEllipse={this.props.optionsOverflowEllipse}
            isHighlighted={isHighlighted}
            createPrefix={createPrefix}
            onMouseHoverChange={(isHovered) => {
              if (isHovered) {
                this.mouseHighlight = true;
                this.setHighlightedIndex(index);
              }
            }}
            onMouseDown={() => {
              this.mouseDownIndex = this.state.highlightedIndex;
            }}
            onClick={(e, isCheckedRightIcon = false) => {
              this.mouseDownIndex = undefined;
              // we only keep dropdown if option is checked and using right icon
              this.selectHighlightedOption(!isCheckedRightIcon);
            }}
            onMouseUp={() => {
              setTimeout(() => {
                this.mouseDownIndex = undefined;
              });
            }}
          />
        </RowWrapper>
      </CellMeasurer>
    );
  };

  getRowCount() {
    const { filteredOptions } = this.state;

    let rowCount = filteredOptions.length;
    if (
      this.props.hideCreateOption &&
      filteredOptions.some((o) => o.isCreate)
    ) {
      rowCount--;
    }

    return rowCount;
  }

  labelId = Math.random();
  triggerRef = createRef();

  render() {
    const { appearance, size, isStaticPlaceholder, errors, disabled } =
      this.props;

    const { inputVal, isOpen, selectedOption } = this.state;

    const hasError = !isEmpty(errors);
    const prefix = !this.props.hideSelectedIcon
      ? get(selectedOption, 'icon', this.props.prefix)
      : this.props.prefix;
    const suffix = !this.props.hideSelectedIcon
      ? get(selectedOption, 'iconRight', this.props.suffix)
      : this.props.suffix;

    const isPrefixIcon = !this.props.prefix;

    let InputRowDropdownIcon = null;
    if (!this.props.hideDropdownIcon) {
      InputRowDropdownIcon =
        this.props.icon === 'search' ? (
          <span className="icon" onMouseDown={this.onInputMouseDown}>
            <Icons.Search />
          </span>
        ) : (
          <span
            className="icon icon-down"
            onMouseDown={this.onInputMouseDown}
            onClick={this.hideDropdown}
            data-testid="toggle-select"
          >
            <EH.Icons.IconArrowDown />
          </span>
        );
    }

    const onPointerDownOutside = (event) => {
      if (
        !this.triggerRef.current.contains(event.target) &&
        this.props.shouldHideOnClickOutside &&
        this.state.isOpen
      ) {
        this.hideDropdown();
      }
    };

    return (
      <FieldWrapper style={this.props.style} ref={this.props.forwardedRef}>
        <FieldLabel id={this.labelId}>{this.props.label}</FieldLabel>
        <PopoverPrimitive.Root open={isOpen || this.props.forcedOpen}>
          <PopoverPrimitive.Trigger ref={this.triggerRef} asChild>
            <StyledInputWrapper
              $color={appearance === 'color'}
              $medium={size === 'medium'}
              $large={size === 'large'}
              $disabled={disabled}
              hasError={hasError}
              isFocused={this.state.isOpen}
              acceptsKeystroke={!this.props.searchable}
              noBorder={this.props.noBorder}
              onClick={this.showDropdown}
              noPadding={this.props.noPadding}
              autoSize={this.props.autoSize}
              ellipsis={this.props.ellipsis}
              minHeight={this.props.inputHeight}
              minWidth={this.props.minWidth}
              isStaticPlaceholder={isStaticPlaceholder}
              style={this.props.inputWrapperStyle}
              data-testid={this.props['data-testid']}
            >
              {this.props.children}
              {prefix && (
                <InputPrefix isIcon={isPrefixIcon}>{prefix}</InputPrefix>
              )}
              <div className="input-container">
                <input
                  id={this.props.inputId}
                  ref={this.inputRef}
                  autoFocus={this.props.autoFocus}
                  style={this.state.inputStyle}
                  value={inputVal}
                  onChange={this.setInputVal}
                  onFocus={this.onFocus}
                  onKeyDown={this.onInputKeyDown}
                  onMouseDown={this.onInputMouseDown}
                  onWheel={this.scrollList}
                  onMouseUp={prevent}
                  placeholder={selectedOption ? '' : this.props.placeholder}
                  type={this.props.type}
                  min={this.props.min}
                  max={this.props.max}
                  step={this.props.step}
                  onBlur={this.onInputBlur}
                  maxLength={this.props.maxLength}
                  readOnly={!this.props.searchable}
                  data-lpignore
                  aria-labelledby={this.props.label ? this.labelId : undefined}
                />

                {this.props.autoSize && (
                  <span ref={this.setReferenceElement} className="measure-this">
                    {inputVal}
                  </span>
                )}

                {suffix ? <InputSuffix>{suffix}</InputSuffix> : null}

                {this.checkCanClearSelect() && (
                  <ClearSelectedOptions
                    className="icon icon-close"
                    tabIndex={-1}
                    onClick={this.onClearClick}
                    aria-label="Clear value"
                    type="button"
                  >
                    <EH.Icons.IconClose />
                  </ClearSelectedOptions>
                )}

                {InputRowDropdownIcon}
              </div>
            </StyledInputWrapper>
          </PopoverPrimitive.Trigger>
          <PopoverPrimitive.Portal container={this.props.containerRef?.current}>
            <PopoverContent
              side={this.props.optionsPlacement}
              align="start"
              alignOffset={this.props.alignOffset}
              height={this.getHeight()}
              sideOffset={2}
              onOpenAutoFocus={preventDefault}
              onCloseAutoFocus={preventDefault}
              avoidCollisions={this.props.avoidCollisions}
              onPointerDownOutside={onPointerDownOutside}
            >
              <List
                ref={this.setListRef}
                tabIndex={null /* Needed to allow scrolling with mouse click */}
                deferredMeasurementCache={this.state.cache}
                rowHeight={this.state.cache.rowHeight}
                rowRenderer={this.rowRenderer}
                estimatedRowSize={ITEM_DEFAULT_HEIGHT_PX}
                scrollToAlignment="start"
                // Using the scrollToIndex state instead of highlightedIndex because we want to update
                // the scroll position only when opening the select
                // The scrollToIndex is a snapshot of the highlightedIndex state taken when the select is opened
                scrollToIndex={this.state.scrollToIndex}
                width={
                  this.props.width ||
                  get(
                    this.inputRef,
                    'current.parentElement.parentElement.clientWidth',
                    0,
                  )
                }
                height={this.getHeight()}
                rowCount={this.getRowCount()}
              />
            </PopoverContent>
          </PopoverPrimitive.Portal>
        </PopoverPrimitive.Root>
        {hasError &&
          errors.map((e) => (e ? <ErrorText key={e}>{e}</ErrorText> : null))}
        {this.props.secondaryText && (
          <SecondaryText>{this.props.secondaryText}</SecondaryText>
        )}
      </FieldWrapper>
    );
  }
}

// TODO: document new props after review
VirtualSelect.defaultProps = {
  appearance: 'default',
  size: 'medium',
  rowLabelSize: 'medium',
  alignOffset: 0,
  avoidCollisions: false,
  disabled: false,
  shouldHideOnClickOutside: true,
  containerRef: undefined,
  creatable: false,
  createPrefix: 'Add',
  nonNullable: false,
  searchable: true,
  value: '',
  autoFocus: false,
  autoSelectOnFocus: false,
  keepFocusAfterSelect: false,
  placeholder: '',
  forcedOpen: false,
  clearInputOnDropdownOpen: true,
  hideUnmatchedOptionsOnFilter: true,
  filterUsingStartsWith: false,
  hideCreateOption: false,
  hideClearIcon: false,
  hideSelectedIcon: false,
  hideDropdownIcon: false,
  icon: 'down',
  gapBetweenItems: 0,
  optionsOverflowEllipse: false,
  optionsPlacement: 'bottom',
  visibleItems: undefined,
  'data-testid': '',
  label: '',
  onOpenChange: noop,
};

export const VirtualSelectWithRef = React.forwardRef((props, ref) => (
  <VirtualSelect {...props} forwardedRef={ref} />
));

VirtualSelectWithRef._styles = {
  FieldWrapper,
  PopoverContent,
  RowLabel,
  InputWrapper,
};

export default VirtualSelectWithRef;
