/* eslint-disable react-compiler/react-compiler */
import React, {
  StrictMode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import parseDate from 'date-fns/parse';
import { cloneDeep, min, some, throttle } from 'lodash';
import { ErrorNoPeople } from 'manage/people-v2/ErrorNoPeople';
import { ErrorNoProjects } from 'manage/projects-v2/ErrorNoProjects';
import { OnboardingManager } from 'OnboardingManager';
import { PmSidebar } from 'pmSidebar';
import { compose } from 'redux';
import { getPmSidebarVisibility, getSerenaReduxData } from 'selectors';
import UndoRedo from 'undo';

import {
  ensureHolidaysLoaded,
  ensureMilestonesLoaded,
  ensureStatusTypesLoaded,
  ensureTagsLoaded,
  ensureTimeoffTypesLoaded,
  fetchLoggedTime,
} from '@float/common/actions';
import { fetchTimersFromRange } from '@float/common/actions/timer';
import CornerCell from '@float/common/components/Schedule/Cell/CornerCell';
import MainCell from '@float/common/components/Schedule/Cell/MainCell';
import DragItem from '@float/common/components/Schedule/Cell/MainCell/Item/DragItem';
import LinkArrowTarget from '@float/common/components/Schedule/Cell/MainCell/Item/LinkedLayer/components/LinkArrowTarget';
import LinkCursorArrow from '@float/common/components/Schedule/Cell/MainCell/Item/LinkedLayer/components/LinkCursorArrow';
import SideCell from '@float/common/components/Schedule/Cell/SideCell';
import TopCell from '@float/common/components/Schedule/Cell/TopCell';
import { useLogAllTasksInTimeRange } from '@float/common/components/Schedule/hooks/useLogAllTasksInTimeRange';
import { TimeRangeInsights } from '@float/common/components/Schedule/insights/TimeRangeInsights';
import { TimeRangePickerWrapper as TimeRangePicker } from '@float/common/components/Schedule/insights/TimeRangePicker';
import SortOptions from '@float/common/components/Schedule/nav/SortOptions';
import SingleDayView from '@float/common/components/Schedule/SingleDayView';
import {
  ContentWrapper,
  getCursor,
  GlobalStyleOverrides,
  GridContainer,
  PinTarget,
  PlaceholderRow,
  RowGroup,
  ScheduleContainer,
  ScrollWrapper,
  SideCellShadowLayer,
  StickyTop,
  TimeRangeInsightsContainer,
  TooltipBoundary,
} from '@float/common/components/Schedule/styles';
import ContextMenu, {
  TASK_EDIT_MODES,
} from '@float/common/components/Schedule/util/ContextMenu';
import { StickyOverlayLayer } from '@float/common/components/Schedule/util/StickyOverlayLayer';
import {
  useViewportSettersContext,
  ViewportContextProvider,
} from '@float/common/components/Schedule/util/ViewportContext';
import useAutoscroller from '@float/common/components/Schedule/Window/useAutoscroller';
import { useVisibleDataWindow } from '@float/common/components/Schedule/Window/useVisibleDataWindow';
import { useWindow } from '@float/common/components/Schedule/Window/useWindow';
import { useStateWithSubscribe } from '@float/common/lib/hooks/useStateWithSubscribe';
import { useTaskMetasPrefetch } from '@float/common/lib/hooks/useTaskMetasPrefetch';
import useContainerSize from '@float/common/lib/useContainerSize';
import { selectIsCompanyWorkDayGetter } from '@float/common/selectors/schedule/isCompanyWorkDay';
import { useCellDataLoadEffect } from '@float/common/serena/Data/useCellsDataLoadEffect';
import { useScheduleDataFetcherWeb } from '@float/common/serena/Data/useScheduleDataFetcherWeb';
import { useScheduleRows } from '@float/common/serena/Data/useScheduleRows';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useIsSingleProjectView } from '@float/common/serena/util/getIsSingleProjectView';
import getScrollbarSize from '@float/common/serena/util/getScrollbarSize';
import { now } from '@float/common/serena/util/timer';
import {
  useAppDispatch,
  useAppSelector,
  useAppSelectorStrict,
  useAppStore,
} from '@float/common/store';
import { todayManager } from '@float/libs/dates';
import { useCallbackRef } from '@float/libs/hooks/useCallbackRef';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { logger } from '@float/libs/logger';
import { moment } from '@float/libs/moment';
import { Hotkeys, useSnackbar, withConfirm } from '@float/ui/deprecated';
import { WelcomeLogTime } from '@float/web/components/legacyOnboarding/Welcome/LogTime';
import WelcomeTips from '@float/web/components/legacyOnboarding/Welcome/WelcomeTips';
import ProjectsCollapseToggle from '@float/web/components/ProjectCollapseToggle';
import { AssignTeamMemberDropdown } from '@float/web/components/schedule/AssignTeamMemberDropdown';
import { getOnboardingStatus } from '@float/web/store/legacyOnboarding/selectors';
import withConfirmDelete from 'components/decorators/withConfirmDelete';
import { LogTimeModal } from 'components/modals/LogTimeModal';
import MilestoneModal from 'components/modals/MilestoneModal';
import ProjectShiftModal from 'components/modals/ProjectShiftModal';
import { DeleteMultiAssignModal } from 'components/popups/DeleteMultiAssign';
import UpdateRepeatingModal from 'components/popups/UpdateRepeating';
import EmailScheduleModal from 'components/schedule/sidebar/share/EmailScheduleModal/EmailScheduleModal';
import ExportCSVModal from 'components/schedule/sidebar/share/ExportCSV/ExportCSV';
import EditTaskModal from 'components/schedule/task/EditTaskModal';
import { ErrorSearchNoResults } from 'components/SearchFilters/components/ErrorSearchNoResults';

import { triggerAction } from '../actions/app';
import { setUndoStackMode } from '../undo/actions';
import createNonScheduleActions from './actions/nonScheduleActions';
import createScheduleActions from './actions/scheduleActions';
import { useLocationHashNavigation } from './hooks/useLocationHashNavigation';
import { useScheduleViewLoadedRum } from './hooks/useScheduleViewLoadedRum';
import { useStorageSyncedState } from './hooks/useStorageSyncedState';
import useEnsureScheduleControls from './nav/useEnsureScheduleControls';
import Controls, { SharedLinkControls } from './util/Controls';

const GLOBAL_WINDOW = window;

function getExpandedProjectIdsKey(reduxData) {
  return `projectViewExpandedProjectIds:${reduxData.user.cid}`;
}

function useLoadScheduleData() {
  const reduxDispatch = useAppDispatch();

  // These aren't dependent on the visible range, but they still need to be
  // loaded when we first visit the schedule.
  useEffect(() => {
    reduxDispatch(ensureTagsLoaded());
    reduxDispatch(ensureTimeoffTypesLoaded());
    reduxDispatch(ensureStatusTypesLoaded());
    reduxDispatch(ensureHolidaysLoaded());
    reduxDispatch(ensureMilestonesLoaded());
  }, [reduxDispatch]);
}

function Schedule(props) {
  // Too much reliance on Refs to make things work, hard to fix so we disable memoization
  'use no memo';
  const { confirm, confirmDelete } = props;

  const reduxDispatch = useAppDispatch();
  const reduxData = useAppSelector(getSerenaReduxData);
  const hasTimeTracking = reduxData.user.time_tracking > 0;
  const onboardingStatus = useAppSelector(getOnboardingStatus);
  const { isSidebarOpen, isSidebarEnabled } = useAppSelector(
    getPmSidebarVisibility,
  );

  const [dragItem, setDragItem] = useState(null);
  const [selectedItems, setSelectedItems] = useState({});
  const [contextMenuPosition, setContextMenuPosition] = useState(null);
  const [actionMode, setActionMode] = useStateWithSubscribe(
    TASK_EDIT_MODES.ADD_EDIT,
    handleActionModeChange,
  );
  const [portalContents, setPortalContents] = useState(null);
  const [linkInfo, setLinkInfo] = useState(null);
  const dragInfo = useRef({});
  const resizeInfo = useRef({});
  const splitInfo = useRef({});
  const personCardDragInfo = useRef(null);
  const mousePositionRef = useRef({});
  const linkedArrowTargetRef = useRef();
  const disableTooltips = useRef(false);
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const boundsHelperRef = useRef({});
  const [draggedRowId, setDraggedRowId] = useState(null);
  const draggedRowGroupRef = useRef();
  const visibleRangeRef = useRef();

  const {
    scrollWrapperRef,
    boundaryRef,
    cornerRef,
    dayWidth,
    numDays,
    hourHeight,
    fetchDataEnabled,
    setFetchDataEnabled,
    mondayStart,
    dates,
    viewType,
    baseColOffset,
    setBaseColOffset,
    numCols,
    setNumCols,
    printMode,
    singleUserView,
    setSingleUserView,
    suvPersonId,
    setSuvPersonId,
    suvWeek,
    setSuvWeek,
    suvSingleDay,
    setSuvHeight,
    logTimeView,
    logMyTimeView,
    rowMetas,
    cellsWrapper: { cells, changes, dispatch },
    hasVerticalScrollbar,
    timeRange,
    setTimeRange,
    timeRangeSettings,
    setTimeRangeSetting,
  } = useScheduleContext();

  const isProjectsView = viewType === 'projects';
  const isScheduleView = viewType === 'people' && !logTimeView;

  const navHeight = 60;
  const [width, height, y, x] = useContainerSize(scrollWrapperRef);
  const [cornerWidth, cornerHeight, , , forceCornerResize] =
    useContainerSize(cornerRef);
  const fetcher = useScheduleDataFetcherWeb(reduxDispatch, reduxData.schedule);
  const [expandedProjectIds, setExpandedProjectIds] = useStorageSyncedState(
    getExpandedProjectIdsKey(reduxData),
    {},
  );
  const { setRowsUpdatedAt } = useViewportSettersContext();
  const { isSingleProjectView, project: singleProject } =
    useIsSingleProjectView();
  const showAssignTeamMemberDropdown =
    isSingleProjectView && singleProject?.canEdit;

  const [disableAnimationCount, setDisableAnimationCount] = useState(0);
  const animations = useMemo(() => {
    return {
      enable() {
        setDisableAnimationCount((c) => Math.max(0, c - 1));
      },
      disable() {
        setDisableAnimationCount((c) => c + 1);
      },
    };
  }, []);

  const { rows, getCurrentRows } = useScheduleRows({
    expandedProjectIds,
    fetcher,
    draggedRowId,
    animations,
    suvPersonId: singleUserView ? suvPersonId : undefined,
  });

  useEffect(() => {
    setRowsUpdatedAt(now());
  }, [rows, setRowsUpdatedAt]);

  useEffect(() => {
    if (singleUserView && !suvPersonId && reduxData.user.people_id) {
      setSuvPersonId(reduxData.user.people_id);
    }
  }, [singleUserView, suvPersonId, setSuvPersonId, reduxData.user.people_id]);

  useEffect(() => {
    forceCornerResize(undefined, singleUserView ? { hide: true } : undefined);
  }, [singleUserView, forceCornerResize]);

  useEffect(() => {
    dispatch({ type: 'LOAD_DATA', dataType: 'timeRange', data: [timeRange] });
  }, [timeRange, dispatch]);

  useLoadScheduleData();

  useEffect(() => {
    if (!logMyTimeView) return;
    const start = dates.fromDescriptor(suvWeek, 0);
    const end = dates.addDays(dates.fromDescriptor(suvWeek + 1, 0), -1);

    reduxDispatch(fetchLoggedTime(suvPersonId, start, end));
    reduxDispatch(fetchTimersFromRange(start, end));
  }, [dates, reduxDispatch, suvPersonId, suvWeek, logMyTimeView]);

  const autoscroller = useAutoscroller({
    dispatch,
    cornerWidth,
  });

  const { showSnackbar, closeSnackbar } = useSnackbar();
  const store = useAppStore();
  const actions = useMemo(() => {
    const nsa = createNonScheduleActions({
      getCurrentRows,
      store,
      setExpandedProjectIds,
      confirm,
      confirmDelete,
      animations,
      actionMode,
      selectedItems,
      setSelectedItems,
      printMode,
      setPortalContents,
      setSingleUserView,
      undoTentativeChanges: cells._helpers.undoTentativeChanges,
      showSnackbar,
      closeSnackbar,
    });

    const sa = createScheduleActions(
      {
        getCurrentRows,
        cells,
        mousePositionRef,
        dragInfo,
        autoscroller,
        store,
        dates,
        dispatch,
        resizeInfo,
        animations,
        disableTooltips,
        cornerWidth,
        cornerHeight,
        dayWidth,
        containerX: x,
        setFetchDataEnabled,
        hourHeight,
        setDragItem,
        setExpandedProjectIds,
        viewType,
        forceUpdate,
        scrollWrapperRef,
        boundsHelperRef,
        personCardDragInfo,
        setDraggedRowId,
        draggedRowGroupRef,
        baseColOffset,
        setBaseColOffset,
        numCols,
        setNumCols,
        numDays,
        visibleRangeRef,
        confirm,
        selectedItems,
        setSelectedItems,
        actionMode,
        setActionMode,
        splitInfo,
        linkInfo,
        setLinkInfo,
        height,
        suvSingleDay,
        logTimeView,
        suvPersonId,
        setSuvPersonId,
        setContextMenuPosition,
        setTimeRange,
      },
      nsa,
    );
    return { ...nsa, ...sa };
  }, [
    actionMode,
    animations,
    autoscroller,
    baseColOffset,
    cells,
    closeSnackbar,
    confirm,
    confirmDelete,
    cornerHeight,
    cornerWidth,
    dates,
    dayWidth,
    dispatch,
    height,
    hourHeight,
    linkInfo,
    logTimeView,
    numCols,
    numDays,
    printMode,
    store,
    getCurrentRows,
    scrollWrapperRef,
    selectedItems,
    setActionMode,
    setBaseColOffset,
    setExpandedProjectIds,
    setFetchDataEnabled,
    setNumCols,
    setSingleUserView,
    setSuvPersonId,
    setTimeRange,
    showSnackbar,
    suvPersonId,
    suvSingleDay,
    viewType,
    x,
  ]);

  function handleActionModeChange(actionMode) {
    if (splitInfo.current.item) {
      if (actionMode === TASK_EDIT_MODES.SPLIT) {
        actions.splitItem();
      } else {
        actions.clearSplitItem();
      }
    }

    if (actionMode !== TASK_EDIT_MODES.LINK) {
      setLinkInfo(null);
    }
  }

  useEffect(() => {
    if (changes.length) {
      // Note that we're mutating the changes array here instead of dispatching
      // an action to clear it as we want to ensure changes are only processed
      // once. If we were to dispatch an action, React could potentially call
      // this effect multiple times before the reducer has a chance to finish.
      const toPersist = changes.splice(0, changes.length);

      // Whenever we save, clear the split info so that we don't run into weird
      // behavior. Not the best solution, but there are a lot of timing
      // considerations that are hard to solve.
      splitInfo.current = {};

      actions.persistChanges(toPersist).catch((err) => {
        // No need to do anything here - persistChanges will show any error
        // prompts on its own. We simply catch to prevent promise bubbling.
        logger.error(
          'An error was encountered while persisting changes in the Schedule',
          err,
        );
      });
    }
  }, [actions, changes]);

  useScheduleViewLoadedRum({
    visibleRangeRef,
    cells,
    fetcher,
  });

  const { actionToTrigger } = reduxData;
  useEffect(() => {
    // We can't show the task modal until we've actually fetched the tasks
    // into the Redux store.
    if (!cells._allLoaded) return;
    if (!fetcher.hasFetchedRange) return;

    if (['addNewTask', 'addNewTimeoff'].includes(actionToTrigger)) {
      const opts = {};
      if (suvSingleDay) {
        opts.start_date = parseDate(suvSingleDay);
        opts.end_date = parseDate(suvSingleDay);
      }

      if (actionToTrigger === 'addNewTask') {
        actions.showAddTaskModal(null, opts);
      } else if (actionToTrigger === 'addNewTimeoff') {
        actions.showAddTimeoffModal(null, opts);
      }
      reduxDispatch(triggerAction(null));
    }

    if (actionToTrigger === 'addNewLoggedTime') {
      const { project_id } = reduxData.defaultDropdownProject;
      actions.showLogTimeModal({
        entity: {
          date: suvSingleDay || todayManager.getToday(),
          project_id,
        },
      });

      reduxDispatch(triggerAction(null));
    }
  }, [
    actions,
    actionToTrigger,
    reduxDispatch,
    cells._allLoaded,
    fetcher.hasFetchedRange,
    suvSingleDay,
    reduxData.defaultDropdownProject,
    reduxData.projects,
    dates,
  ]);

  useLocationHashNavigation({
    loaded: cells._allLoaded && fetcher.hasFetchedRange,
    showEditTaskModalForId: actions.showEditTaskModalForId.bind(actions),
    showNotificationsModal: actions.showNotificationsModal.bind(actions),
  });

  const setLinkClipArea = useCallback((scrollX, scrollY) => {
    const el = document.getElementById('linkedTaskLineClip');
    el?.firstChild?.setAttribute('x', scrollX);
    el?.firstChild?.setAttribute('y', scrollY);
  }, []);

  const projectsToggleRef = useRef(null);
  const [showProjectsToggle, setShowProjectsToggle] = useState(false);

  const dataWindow = useVisibleDataWindow(rows);
  const scheduleWindow = useWindow({
    containerWidth: width + x,
    containerHeight: height,
    containerY: y,
    rows,
    rowMetas,
    cells,
    renderers: {
      placeholderRow: PlaceholderRow,
      rowGroup: RowGroup,
      main: MainCell,
      side: (!singleUserView || viewType === 'projects') && SideCell,
      top: TopCell,
      pinTarget: PinTarget,
    },
    renderersConfig: {
      pinTarget: {
        isSideMenuDisplayed: singleUserView,
      },
    },
    actions,
    helpers: {
      isColumnFetched: (colIdx) => {
        if (!fetcher.isColumnFetched(colIdx)) return false;
        if (logMyTimeView) {
          const start = dates.fromDescriptor(suvWeek, 0);
          const fetchKey = `${suvPersonId}:${start}`;
          return reduxData.fetchedLoggedTime[fetchKey];
        }
        return true;
      },
    },
    animations,
    disableTooltips,
    draggedRowId,
    draggedRowGroupRef,
    linkedArrowTargetRef,
    onScroll: setLinkClipArea,
    onVisibleRangeChange: useCallbackRef((visibleRange) => {
      const visibleRangePayload = {
        ...visibleRange,
        logTimeView,
        withLoggedTimes: hasTimeTracking && !logMyTimeView,
      };

      visibleRangeRef.current = visibleRangePayload;

      if (fetcher && fetchDataEnabled) {
        fetcher.ensureRangeFetched(visibleRangePayload);
      }

      dataWindow.handleVisibleRangeChange(visibleRange);
    }),
  });

  const { elements, totalHeight, totalWidth, rowsPositionManager } =
    scheduleWindow;
  boundsHelperRef.current = rowsPositionManager;

  useCellDataLoadEffect(true);

  useEffect(() => {
    if (fetcher && fetchDataEnabled && visibleRangeRef.current) {
      visibleRangeRef.current.withLoggedTimes = hasTimeTracking;
      fetcher.ensureRangeFetched(visibleRangeRef.current);
    } else {
      // Wait for the scroll to take effect before enabling data fetching
      setTimeout(() => setFetchDataEnabled(true), 150);
    }
  }, [
    numDays,
    dayWidth,
    fetchDataEnabled,
    setFetchDataEnabled,
    fetcher,
    hasTimeTracking,
  ]);

  useEffect(() => {
    if (logMyTimeView) {
      if (scrollWrapperRef.current) {
        scrollWrapperRef.current.scrollLeft = 0;
      }
    }
  }, [scrollWrapperRef, logMyTimeView, suvWeek]);

  useEffect(() => {
    setActionMode(TASK_EDIT_MODES.ADD_EDIT);
  }, [setActionMode, logMyTimeView]);

  const { prefetchLastProjectTasks } = useTaskMetasPrefetch();

  useOnMount(() => {
    prefetchLastProjectTasks();
  });

  useEffect(() => {
    if (!logMyTimeView) {
      actions.scrollToToday();
    }
  }, [scrollWrapperRef.current, logMyTimeView, dayWidth]); // eslint-disable-line

  useEffect(() => {
    if (scrollWrapperRef.current) scrollWrapperRef.current.scrollTop = 0;
  }, [viewType]); // eslint-disable-line

  function getNoResults() {
    if (rows.length > 0) return null;

    const canAct = Number(reduxData.user.account_tid) !== 4;
    const isSharedLinkView = reduxData?.user?.shared_link_view;
    const canCreatePeople = canAct && !isSharedLinkView;

    const hasActivePerson = some(reduxData.allPeople, 'active');
    if (!hasActivePerson) {
      return (
        <ErrorNoPeople
          canCreatePeople={canCreatePeople}
          isSharedLink={isSharedLinkView}
          onClickAddPerson={actions.showAddPersonModal}
        />
      );
    }

    if (isProjectsView) {
      const hasActiveProject = some(reduxData.projects, 'active');

      if (!hasActiveProject) {
        return (
          <ErrorNoProjects
            canCreateProjects={canAct}
            onClickAddProject={actions.showAddProjectModal}
          />
        );
      }
    }

    return <ErrorSearchNoResults />;
  }

  const optimisticUpdate = useCallback(
    function (change) {
      // Note that adding/updating a task is the only scenario where we want to
      // optimistically close the task modal. With replace/insert, the server
      // calculates the changes, which means we need to wait for the response to
      // render the correct data.
      if (actionMode === TASK_EDIT_MODES.ADD_EDIT) {
        actions.hideTaskModal();
      }

      if (actionMode === TASK_EDIT_MODES.REPLACE) {
        if (cells._helpers.intersectsRepeatingEntity(change)) {
          actions.showReplaceRepeatOverlapModal();
        }
        if (cells._helpers.intersectsMultiAssignEntity(change)) {
          actions.showReplaceMultiAssignOverlapModal();
        }
      }

      if (actionMode === TASK_EDIT_MODES.INSERT) {
        if (cells._helpers.intersectsRepeatingEntity(change)) {
          actions.showInsertRepeatOverlapModal();
        }
        if (cells._helpers.intersectsMultiAssignEntity(change)) {
          actions.showInsertMultiAssignOverlapModal();
        }
      }
    },
    [cells, actions, actionMode],
  );

  const suvDate = useMemo(() => {
    return moment(dates.fromDescriptor(suvWeek, 0));
  }, [suvWeek, dates]);

  useEffect(() => {
    if (!singleUserView) return;
    setSuvHeight(totalHeight);
  }, [singleUserView, setSuvHeight, totalHeight]);

  const { logAllTasksInTimeRange, isLoading: isLogAllLoading } =
    useLogAllTasksInTimeRange();

  function handleLogAllTasksInTimeRange() {
    const peopleIds = Array.from(
      new Set(rows.filter((x) => x.isLogTimeRow).map((x) => x.peopleId)),
    );

    logAllTasksInTimeRange({
      peopleIds,
    });
  }

  const insights = !logMyTimeView ? (
    <TimeRangeInsightsContainer singleUserView={singleUserView}>
      <TimeRangePicker
        insightsPreferredUnit={timeRangeSettings.insightsPreferredUnit}
        hasInsightsPreferredUnitToggle={isScheduleView || isProjectsView}
        onChange={(orig, entity) => {
          actions.onTimeRangePresetChange(orig, entity);
        }}
        onInsightsPreferredUnitChange={(unit) => {
          setTimeRangeSetting('insightsPreferredUnit', unit);
        }}
      />
      {timeRange.start_date && (
        <TimeRangeInsights
          fetcher={fetcher}
          isDragging={Boolean(dragInfo.current.items?.length)}
          onClick={handleLogAllTasksInTimeRange}
          showLoader={isLogAllLoading}
        />
      )}
    </TimeRangeInsightsContainer>
  ) : null;

  const sort = (
    <SortOptions
      actions={actions}
      viewType={viewType}
      updatePrefs={actions.updatePrefs}
      prefs={reduxData.user.prefs}
      isLogTimeView={logTimeView}
      hasInsights={Boolean(timeRange.start_date) && Boolean(timeRange.end_date)}
    />
  );

  let Inner;
  const Corner = logMyTimeView ? null : (
    <StickyOverlayLayer>
      {singleUserView ? (
        <CornerCell singleUserView insights={insights} />
      ) : (
        <>
          {!rows.length ? null : (
            <SideCellShadowLayer
              style={{
                height: totalHeight,
                top: 52,
              }}
            />
          )}
          <CornerCell
            actions={actions}
            cornerRef={cornerRef}
            insights={insights}
            sort={sort}
          />
        </>
      )}
    </StickyOverlayLayer>
  );

  if (suvSingleDay) {
    Inner = (
      <SingleDayView
        cells={cells}
        actions={actions}
        row={rows[0]}
        rowMeta={rowMetas.get(rows[0].id)}
      />
    );
  } else {
    const disableAnimation = disableAnimationCount > 0 || !fetchDataEnabled;
    Inner = (
      <>
        {Corner}
        <ScrollWrapper
          id="schedule-scroll-wrapper"
          ref={scrollWrapperRef}
          disableAnimation={disableAnimation}
          className={disableAnimation ? 'scrollWrapperDisableAnimation' : ''}
          headerHeight={navHeight}
          dayWidth={dayWidth}
          logMyTimeView={logMyTimeView}
          style={{
            background: singleUserView ? 'white' : undefined,
            marginLeft: 0,
            overflowY:
              typeof hasVerticalScrollbar === 'undefined'
                ? undefined
                : hasVerticalScrollbar
                  ? 'scroll'
                  : 'hidden',
          }}
        >
          <GridContainer
            style={{
              height: totalHeight + 56,
              width: totalWidth + 1,
              transition:
                resizeInfo.current.item ||
                disableAnimationCount > 0 ||
                !fetchDataEnabled
                  ? ''
                  : 'height 0.25s ease',
            }}
            dayWidth={dayWidth}
            numDays={numDays}
          >
            <StickyTop>{elements.top}</StickyTop>
            <LinkArrowTarget
              linkedArrowTargetRef={linkedArrowTargetRef}
              actionMode={actionMode}
              totalHeight={totalHeight}
              totalWidth={totalWidth}
              cornerWidth={cornerWidth}
              viewType={viewType}
            />
            <ContentWrapper
              height={totalHeight}
              scrollbarSize={getScrollbarSize()}
              onContextMenu={actions.onContextMenu}
            >
              {elements.main}
            </ContentWrapper>
          </GridContainer>
          {showAssignTeamMemberDropdown && (
            <AssignTeamMemberDropdown totalHeight={totalHeight} />
          )}
        </ScrollWrapper>
      </>
    );
  }

  useEffect(() => {
    if (!logTimeView) return;
    if (hasTimeTracking) return;
    GLOBAL_WINDOW.location = '/';
  }, [logTimeView, hasTimeTracking]);

  useEffect(() => {
    reduxDispatch(setUndoStackMode(logTimeView ? 'logTime' : 'schedule'));
  }, [reduxDispatch, logTimeView]);

  useEnsureScheduleControls({
    logTimeView,
    logMyTimeView,
    viewType,
    actions,
    visibleRangeRef,
    dates,
    suvWeek,
    setSuvWeek,
    mondayStart,
    suvDate,
  });

  // const { account_tid } = reduxData.user;
  // const canChoosePerson = account_tid != 4;

  const onScheduleMouseMove = useMemo(() => {
    const throttled = throttle((e) => {
      const isOnSideCell = e.clientX < 320;
      if (isOnSideCell) {
        if (!projectsToggleRef.current) {
          projectsToggleRef.current = 'side-cell';
          setShowProjectsToggle(true);
        }
      } else if (projectsToggleRef.current) {
        projectsToggleRef.current = null;
        setShowProjectsToggle(false);
      }
    }, 500);
    return (e) => {
      e.persist();
      return throttled(e);
    };
  }, []);

  const isCompanyWorkDay = useAppSelectorStrict(selectIsCompanyWorkDayGetter);

  const keyMap = useMemo(
    () => ({
      Escape: () => setSelectedItems({}),
      '/': () =>
        logMyTimeView
          ? setSuvWeek(dates.getCurrentWeek())
          : actions.scrollToToday(),
      ArrowLeft: () =>
        logMyTimeView ? setSuvWeek(suvWeek - 1) : actions.scrollWeeks(-1),
      ArrowRight: () =>
        logMyTimeView ? setSuvWeek(suvWeek + 1) : actions.scrollWeeks(1),
      ArrowUp: () => actions.scrollRows(-1),
      ArrowDown: () => actions.scrollRows(1),
    }),
    [actions, logMyTimeView, suvWeek, setSuvWeek, dates],
  );

  const calcEntityEndDate = useCallback(
    (entity) => {
      const endDates = entity.people_ids.map((id) => {
        const rowId = `person-${id}`;
        return cells._helpers.calcEntityEndDate(cells, rowId, entity);
      });
      return min(endDates);
    },
    [cells],
  );

  const getTotalDaysInRange = useCallback(
    (data) => {
      return cells._helpers.getTotalDaysInRange({ ...data, cells });
    },
    [cells],
  );

  const onTaskDelete = useCallback(
    async (entity) => {
      // If the user is trying to delete from the middle of a repeat task
      // via the task modal, it will have updated the start and end dates.
      // This breaks undo/redo, so adjust the dates before performing
      // the repeat deletion in case the user triggers undo.
      let id;
      let type;
      if (entity.isTimeoff) {
        id = entity.timeoff_id;
        type = 'timeoff';
      } else if (entity.isStatus) {
        id = entity.status_id;
        type = 'status';
      } else {
        id = entity.task_id;
        type = 'task';
      }

      const change = {
        type,
        id: Number(id),
        entity,
        originalEntity: cloneDeep(entity),
        isRemove: true,
        clickedOnPersonId: entity.clickedOnPersonId,
      };

      try {
        await actions.persistChanges([change]);
        actions.hideTaskModal();
      } catch (e) {
        // No need to do anything here - persistChanges will show any error
        // prompts on its own. We simply catch to prevent the task modal
        // from being hidden and losing the user's changes.
      }
    },
    [actions],
  );

  const onTaskSave = useCallback(
    async (originalEntity, entity) => {
      if (entity.full_day) {
        entity.hours = null;
      }

      if (entity.isTimeoff) {
        delete entity.project_id;
      }

      let type = 'task';
      let id = Number(entity.task_id);

      if (entity.isTimeoff) {
        type = 'timeoff';
        id = Number(entity.timeoff_id);

        if (
          Array.isArray(entity.people_id) &&
          Array.isArray(entity.people_ids)
        ) {
          delete entity.people_id;
        }
      }

      if (entity.isStatus) {
        type = 'status';
        id = Number(entity.status_id);
      }

      const changesToPersist = [
        {
          type,
          id,
          entity,
          originalEntity,
          isCreate: !entity.task_id && !entity.timeoff_id && !entity.status_id,
          isFirstTask: entity.isFirstTask,
          optimisticUpdate,
          nothingWasChanged: actions.hideTaskModal,
          onError: () => {
            actions.showEditTaskModal(originalEntity);
          },
        },
      ];

      let dayDelta = 0;
      if (entity.root_task_id && entity.end_date !== originalEntity.end_date) {
        // Figure out how many work days the activeEntity was adjusted by.
        // This is the number of work days that we want to adjust the other
        // multiselected entities by as well.
        dayDelta = cells._helpers.countWorkDays(
          cells,
          `person-${entity.clickedOnPersonId}`,
          dates.min(entity.end_date, originalEntity.end_date),
          dates.max(entity.end_date, originalEntity.end_date),
        );
        if (entity.end_date < originalEntity.end_date) {
          dayDelta *= -1;
        }
      }

      cells._helpers.addAssociatedChanges(changesToPersist, { dayDelta });

      await actions.persistChanges(changesToPersist);
      actions.hideTaskModal();
    },
    [cells, actions, dates, optimisticUpdate],
  );

  const handleIsFullDayTimeoff = useCallback(
    (personId, day) => {
      return cells._helpers.isFullDayTimeoff(cells, `person-${personId}`, day);
    },
    [cells],
  );

  const handleCalcEntityLength = useCallback(
    (entity) => cells._helpers.calcEntityLength(null, '_company', entity),
    [cells._helpers],
  );

  const handleCalcEntityStartDate = useCallback(
    (entity) => cells._helpers.calcEntityStartDate(null, '_company', entity),
    [cells._helpers],
  );

  const handleCalcEntityEndDate = useCallback(
    (entity) => cells._helpers.calcEntityEndDate(null, '_company', entity),
    [cells._helpers],
  );

  const handleHide = useCallback(() => setContextMenuPosition(null), []);
  const getAssociatedChangesForEntity = useCallback(
    (entity) => {
      return cells._helpers.getAssociatedChangesForEntity(cells, entity, {
        noMutations: true,
      });
    },
    [cells],
  );

  // Passing the cursor as inline style because
  // when passed as a styled-component CSS doesn't work
  // well with Safari
  // see: https://linear.app/float-com/issue/CS-1645/in-the-safari-browser-the-mouse-cursor-doesnt-change-when-youre-in-the#comment-78f28600
  let cursor = '';

  if (actionMode === TASK_EDIT_MODES.ADD_EDIT) {
    if (dragItem) cursor = 'grabbing';
  } else {
    cursor = `url(${getCursor(actionMode)}), auto`;
  }

  const scheduleContainerStyles = useMemo(
    () => ({
      cursor,
    }),
    [cursor],
  );

  return (
    <ScheduleContainer
      id="schedule-container"
      className={cx({
        'single-user-schedule': singleUserView && !suvSingleDay,
        'single-day-schedule': suvSingleDay,
        'log-time-view': logMyTimeView,
        'link-confirm-pending': !!linkInfo?.base,
        'is-dragging': !!dragItem,
        'is-resizing': !!resizeInfo.current?.item,
        'show-link-lines':
          actionMode === TASK_EDIT_MODES.LINK || isProjectsView,
      })}
      style={scheduleContainerStyles}
      onMouseMove={isProjectsView ? onScheduleMouseMove : undefined}
    >
      <GlobalStyleOverrides />
      <StrictMode>
        {dragItem && <DragItem actions={actions} dragItem={dragItem} />}
        {Inner}
        {getNoResults()}
      </StrictMode>
      {fetcher.hasFetchedRange &&
        !logMyTimeView &&
        !suvSingleDay &&
        (reduxData.user.shared_link_view ? (
          <SharedLinkControls
            user={reduxData.user}
            dates={dates}
            actions={actions}
            visibleRangeRef={visibleRangeRef}
          />
        ) : (
          <Controls
            actions={actions}
            visibleRangeRef={visibleRangeRef}
            isSidebarOpen={isSidebarOpen}
            actionMode={actionMode}
          />
        ))}
      <EditTaskModal
        logTimeView={logTimeView}
        getTotalDaysInRange={getTotalDaysInRange}
        hasVaryingWorkHoursInRange={cells._helpers.hasVaryingWorkHoursInRange}
        getAssociatedChangesForEntity={getAssociatedChangesForEntity}
        calcEntityEndDate={calcEntityEndDate}
        onHide={actions.hideTaskModal}
        onDelete={onTaskDelete}
        onSave={onTaskSave}
      />
      <ProjectShiftModal
        isWorkDay={isCompanyWorkDay}
        calcEntityLength={handleCalcEntityLength}
        calcEntityStartDate={handleCalcEntityStartDate}
        calcEntityEndDate={handleCalcEntityEndDate}
      />
      <MilestoneModal isWorkDay={isCompanyWorkDay} />
      <UpdateRepeatingModal />
      <DeleteMultiAssignModal />
      <EmailScheduleModal />
      <LogTimeModal
        actions={actions}
        isFullDayTimeoff={handleIsFullDayTimeoff}
        isLogMyTimeView={logMyTimeView}
        onSave={actions.persistChanges}
        pathname={props.location.pathname}
      />
      <ExportCSVModal
        rows={rows}
        cells={cells}
        mondayStart={mondayStart}
        fetcher={fetcher}
        dates={dates}
        rowMetas={rowMetas}
        viewType={viewType}
      />
      <ContextMenu
        position={contextMenuPosition}
        cornerWidth={cornerWidth}
        cornerHeight={cornerHeight}
        logTimeView={logTimeView}
        actionMode={actionMode}
        setPosition={setContextMenuPosition}
        hide={handleHide}
        setActionMode={setActionMode}
      />
      <Hotkeys key={logMyTimeView} noOverride keyMap={keyMap} />
      <UndoRedo />
      <LinkCursorArrow
        linkInfo={linkInfo}
        cells={cells}
        headerHeight={navHeight + cornerHeight}
        cornerWidth={cornerWidth}
        linkedArrowTargetRef={linkedArrowTargetRef}
        mousePositionRef={mousePositionRef}
      />
      <TooltipBoundary
        ref={boundaryRef}
        style={{ zIndex: -1 }}
        headerHeight={navHeight}
      />
      {isProjectsView && showProjectsToggle && !isSingleProjectView && (
        <ProjectsCollapseToggle
          expandedProjectIds={expandedProjectIds}
          totalHeight={totalHeight}
          onClick={actions.toggleCollapsedProject}
        />
      )}
      {isSidebarEnabled && <PmSidebar portal={false} actions={actions} />}
      {!logTimeView && !onboardingStatus.isComplete && (
        <WelcomeTips viewType={viewType} />
      )}
      {logTimeView && onboardingStatus.showLogTime && <WelcomeLogTime />}
      <OnboardingManager />
      {portalContents}
    </ScheduleContainer>
  );
}

export function SerenaSchedulePreload() {
  useLoadScheduleData();

  return null;
}

const MemoSchedule = React.memo(Schedule);

function SerenaSchedule(props) {
  return (
    <ViewportContextProvider>
      <MemoSchedule {...props} />
      <div id="serena-portal" />
    </ViewportContextProvider>
  );
}

const Serena = compose(
  withConfirm,
  withConfirmDelete,
)(React.memo(SerenaSchedule));

export { Serena };
