import React, { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { useDrag } from 'react-use-gesture';
import parseDate from 'date-fns/parse';
import styled from 'styled-components';

import DateSlider from '@float/common/components/DateSlider/DateSlider';
import { getFilteredEntities } from '@float/common/search/selectors/filteredEntities';
import { getUser } from '@float/common/selectors/currentUser';
import { getDefaultDropdownProject } from '@float/common/selectors/projects';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { useAppSelectorStrict, useAppStore } from '@float/common/store';
import { AccountType } from '@float/constants/accounts';
import { useResizeObserver } from '@float/libs/web/hooks/useResizeObserver';

import { getContent, getLoggedHours } from './SingleDayView.helpers';
import TopDatePicker from './TopDatePicker';
import TopPersonPicker from './TopPersonPicker';

// TODO: earhart - 112
const Wrapper = styled.div`
  height: ${(p) => p.innerHeight - 112}px;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  flex-grow: 1;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  background: white;
`;

const BottomSliderContainer = styled.div`
  height: 54px;
  min-height: 54px;
  position: relative;
`;

const DayPositioner = styled(animated.div)`
  position: absolute;
  height: 100%;
`;

const DayContainer = styled.div`
  position: absolute;
  width: ${(p) => p.dayWidth}px;
  transform: translateX(${(p) => p.dayNum * p.dayWidth}px);
`;

const DayWrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

function SingleDayView(props) {
  const { cells, actions, row, rowMeta } = props;
  const {
    dates,
    suvPersonId,
    setSuvPersonId,
    suvSingleDay,
    setSuvSingleDay,
    mondayStart,
    dayWidth,
    hourHeight,
    leftHiddenDays,
    rightHiddenDays,
    logTimeView,
  } = useScheduleContext();

  const contentWrapperRef = useRef();
  const [innerHeight, setInnerHeight] = useState(window.innerHeight);

  const suvSingleDayNum = dates.toNum(suvSingleDay);
  const [colIdx, subCol] = dates.toDescriptor(suvSingleDay);

  const topCell = cells[`top:${colIdx}`];

  const contentProps = {
    cells,
    dates,
    suvPersonId,
    dayWidth,
    hourHeight,
    actions,
    row,
    rowMeta,
    logTimeView,
  };

  const { items, scheduledHours } = getContent(contentProps, suvSingleDayNum);

  const loggedHours = useAppSelectorStrict((state) =>
    getLoggedHours(
      {
        ...contentProps,
        filteredEntities: getFilteredEntities(state),
        filters: getActiveFilters(state),
      },
      suvSingleDayNum,
    ),
  );

  const { items: prevDayItems } = getContent(contentProps, suvSingleDayNum - 1);
  const { items: nextDayItems } = getContent(contentProps, suvSingleDayNum + 1);

  const handleResize = useCallback(() => {
    setInnerHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // eslint-disable-line

  const canChoosePerson = useAppSelectorStrict(
    (state) => getUser(state).account_tid !== AccountType.Member,
  );

  const manuallyAdjustedRef = useRef(false);
  const horizontallyLockedRef = useRef(false);

  const [{ xy }, set] = useSpring(() => {
    return {
      xy: [dayWidth * suvSingleDayNum, 0],
    };
  });

  useEffect(() => {
    set({ xy: [dayWidth * suvSingleDayNum, 0] });
  }, [window.innerWidth]); // eslint-disable-line

  useEffect(() => {
    if (manuallyAdjustedRef.current) {
      manuallyAdjustedRef.current = false;
      return;
    }

    set({ xy: [dayWidth * suvSingleDayNum, 0] });
  }, [suvSingleDayNum]); // eslint-disable-line

  const bind = useDrag(
    ({ event, down, delta, last }) => {
      const absX = Math.abs(delta[0]);
      const absY = Math.abs(delta[1]);
      if (!last && absY > 2 && absY > absX) {
        // The user is dragging more vertically than they are horizontally.
        // Prevent any horizontal adjustment.
        horizontallyLockedRef.current = true;
        set({ xy: [dayWidth * suvSingleDayNum, 0] });
        return;
      }

      if (down && !horizontallyLockedRef.current) {
        set({ xy: [dayWidth * suvSingleDayNum - delta[0], 0] });
      }

      if (last) {
        if (horizontallyLockedRef.current) {
          horizontallyLockedRef.current = false;
          return;
        }

        if (delta[0] < -2 || delta[0] > 2) {
          event.preventDefault();
        }

        if (delta[0] > 40) {
          set({ xy: [dayWidth * (suvSingleDayNum - 1), 0] });
          manuallyAdjustedRef.current = true;
          setSuvSingleDay(dates.fromNum(suvSingleDayNum - 1));
        } else if (delta[0] < -40) {
          set({ xy: [dayWidth * (suvSingleDayNum + 1), 0] });
          manuallyAdjustedRef.current = true;
          setSuvSingleDay(dates.fromNum(suvSingleDayNum + 1));
        } else {
          // The user didn't drag far enough to trigger a week swipe, abort.
          set({ xy: [dayWidth * suvSingleDayNum, 0] });
        }
      }
    },
    { event: { passive: false, capture: true } },
  );

  const store = useAppStore();

  const handleContainerClick = (evt) => {
    if (!actions.isRowEditable(row)) return;

    if (logTimeView) {
      const { tagName } = evt.target;
      if (tagName === 'BUTTON' || tagName === 'INPUT') {
        return;
      }

      const { project_id } = getDefaultDropdownProject(store.getState());

      actions.showLogTimeModal({
        entity: {
          date: suvSingleDay,
          project_id,
          people_id: suvPersonId,
        },
      });
    } else {
      const opts = {};

      if (suvSingleDay) {
        opts.start_date = parseDate(suvSingleDay);
        opts.end_date = parseDate(suvSingleDay);
      }

      actions.showAddTaskModal(null, opts);
    }
  };

  const todayHeight = (scheduledHours + 0.5) * hourHeight;
  const [minHeight, setMinHeight] = useState(0);

  useResizeObserver(contentWrapperRef, () =>
    setMinHeight(contentWrapperRef.current.clientHeight),
  );

  return (
    <Wrapper innerHeight={innerHeight}>
      {canChoosePerson && (
        <TopPersonPicker
          suvPersonId={suvPersonId}
          setSuvPersonId={setSuvPersonId}
        />
      )}
      <TopDatePicker
        suvSingleDay={suvSingleDay}
        setSuvSingleDay={setSuvSingleDay}
        dates={dates}
        mondayStart={mondayStart}
        highlights={topCell && topCell.highlights[subCol]}
        loggedHours={loggedHours}
        logTimeView={logTimeView}
      />
      <ContentWrapper ref={contentWrapperRef}>
        <DayPositioner
          {...bind()}
          style={{
            transform: xy.interpolate((x, y) => `translateX(-${x}px)`),
          }}
        >
          <DayContainer dayNum={suvSingleDayNum - 1} dayWidth={dayWidth}>
            <DayWrapper
              style={{ height: todayHeight, minHeight, maxHeight: todayHeight }}
            >
              {prevDayItems}
            </DayWrapper>
          </DayContainer>
          <DayContainer
            dayNum={suvSingleDayNum}
            dayWidth={dayWidth}
            onClick={handleContainerClick}
          >
            <DayWrapper style={{ height: todayHeight, minHeight }}>
              {items}
            </DayWrapper>
          </DayContainer>
          <DayContainer dayNum={suvSingleDayNum + 1} dayWidth={dayWidth}>
            <DayWrapper
              style={{ height: todayHeight, minHeight, maxHeight: todayHeight }}
            >
              {nextDayItems}
            </DayWrapper>
          </DayContainer>
        </DayPositioner>
      </ContentWrapper>
      <BottomSliderContainer>
        <DateSlider
          dates={dates}
          leftHiddenDays={leftHiddenDays}
          rightHiddenDays={rightHiddenDays}
          day={suvSingleDay}
          setDay={setSuvSingleDay}
          width={window.innerWidth}
        />
      </BottomSliderContainer>
    </Wrapper>
  );
}

export default SingleDayView;
