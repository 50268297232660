import { createSelector } from 'reselect';

import { getIsEntityLoggable } from '@float/common/lib/rights';
import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import {
  getUser,
  selectDatesManager,
} from '@float/common/selectors/currentUser';
import { getLockPeriodDates } from '@float/common/selectors/lockLoggedTime';
import { getPhasesMapRaw } from '@float/common/selectors/phases';
import { getProjectsMapRaw } from '@float/common/selectors/projects';
import { selectIsWorkDayGetter } from '@float/common/selectors/schedule/isWorkDay';
import { isDateInLockPeriod } from '@float/common/serena/util/lockPeriod';
import { forEachEntityDate } from '@float/libs/datesRepeated/forEachEntityDate';
import { Person } from '@float/types/person';
import { Task } from '@float/types/task';
import { TaskStatusEnum } from '@float/types/taskStatus';

import {
  getPersonInsightRange,
  isOutsideRange,
} from '../helpers/isOutsideRange';
import { getTimeRangeLoggedTimesByPerson } from './getTimeRangeLoggedTimesByPerson';
import { getTimeRangeTasksByPerson } from './getTimeRangeTasksByPerson';

/**
 * A selector that returnd an iterative method (https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array#iterative_methods)
 * that accepts a callback.
 * 
 * The callback function is called sequentially on all the logged time suggestions of the given time range for the given person.
 * 
 * Example:
 * 
 * const forEachLoggedTimeSuggestion =
      selectIteratorOverAllLoggedTimeSuggestions(store.getState(), {
        startDate: start,
        endDate: end,
      });

    const changes: BulkLoggedTimeSuggestion[] = [];

    for (const personId of peopleIdsToLog) {
      const person = selectPersonById(store.getState(), personId);

      if (!person) continue;

      // A suggestion can be represented with a task, date and person 
      forEachLoggedTimeSuggestion(person, (task, date) => {
        changes.push({
          task,
          date,
          person,
          priority: getPriority(task, person),
        });
      });
    }
 */
export const selectIteratorOverAllLoggedTimeSuggestions = createSelector(
  [
    (_: ReduxStateStrict, params: { startDate: string; endDate: string }) =>
      params,
    selectDatesManager,
    getTimeRangeTasksByPerson,
    getTimeRangeLoggedTimesByPerson,
    getLockPeriodDates,
    selectIsWorkDayGetter,
    getUser,
    getProjectsMapRaw,
    getPhasesMapRaw,
  ],
  (
    params,
    dates,
    tasks,
    loggedTimes,
    lockPeriodDates,
    getIsWorkDay,
    user,
    projects,
    phases,
  ) => {
    const entityLoggableParams = {
      user,
      projects,
      phases,
    };

    return (person: Person, callback: (task: Task, date: string) => void) => {
      const workDaysInRange = new Set<string>();

      const loggedTaskSuggestions = new Map<string, Set<string>>();

      const range = getPersonInsightRange(
        dates,
        person,
        dates.toNum(params.startDate),
        dates.toNum(params.endDate),
      );

      for (let i = range.start; i <= range.end; i += 1) {
        const date = dates.fromNum(i);

        if (getIsWorkDay(person, date)) {
          workDaysInRange.add(date);
        }
      }

      // Calculate all the logged times and keep track of all the logged
      // suggestions to not calculate those in the allocated hours
      for (const entity of loggedTimes.get(person.people_id) || []) {
        if (
          entity.task_id &&
          entity.reference_date && // The reference_date is used to check if a suggestion has been commited
          !isOutsideRange(range, dates.toNum(entity.reference_date))
        ) {
          const taskId = String(entity.task_id);

          const loggedDates = loggedTaskSuggestions.get(taskId);

          if (loggedDates) {
            loggedDates.add(entity.reference_date);
          } else {
            const loggedDates = new Set<string>();
            loggedDates.add(entity.reference_date);
            loggedTaskSuggestions.set(taskId, loggedDates);
          }
        }

        if (!isOutsideRange(range, dates.toNum(entity.date))) {
          // Days with a logged time should be considered working days
          workDaysInRange.add(entity.date);
        }
      }

      // Calculates the suggested logged times hours
      for (const entity of tasks.get(person.people_id) || []) {
        if (entity.status === TaskStatusEnum.Tentative) continue;
        if (entity.status === TaskStatusEnum.Draft) continue;
        if (!getIsEntityLoggable(entity, entityLoggableParams)) continue; // Skip non loggable tasks

        const loggedDates =
          loggedTaskSuggestions.get(entity.task_id) ?? new Set();

        forEachEntityDate(dates, entity, (dateNum) => {
          const date = dates.fromNum(dateNum);

          if (!workDaysInRange.has(date)) return;

          if (isDateInLockPeriod(date, lockPeriodDates.latest)) {
            return;
          }

          // Has the suggestion already been logged?
          if (loggedDates.has(date)) return;

          loggedDates.add(date);

          callback(entity, date);
        });
      }
    };
  },
);
