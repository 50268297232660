import React, { useCallback, useMemo } from 'react';
import { get, useFormContext, useFormState } from 'react-hook-form';
import { t } from '@lingui/macro';

import { FormField } from '../components/FormFieldsSection';
import { SidePanelInput } from '../components/SidePanelInput';

const ProjectCodeField = () => {
  const { setValue } = useFormContext();
  const { errors } = useFormState();

  const hasError = Boolean(get(errors, 'project.project_code'));
  const themeOverrides = useMemo(
    () => ({
      ...(hasError && {
        hover: {
          borderColor: '#DC3D43',
        },
        focus: {
          borderColor: '#DC3D43',
        },
      }),
    }),
    [hasError],
  );

  const onKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      // Need to prevent the default submit behaviour
      e.preventDefault();
      (e.target as HTMLInputElement).blur();
    }
  }, []);

  const onChange = useCallback(
    (arg: React.ChangeEvent<HTMLInputElement>) => {
      setValue('project.project_code', arg.target.value, {
        shouldDirty: false,
        shouldValidate: true,
        shouldTouch: false,
      });
    },
    [setValue],
  );

  return (
    <FormField
      label={t`Project code`}
      alignLabel="center"
      htmlFor="project.project_code"
      stopClickPropagation
    >
      <SidePanelInput
        type="text"
        inputMode="text"
        variant="spacious"
        name="project.project_code"
        placeholder={t`Add project code`}
        onChange={onChange}
        onKeyDown={onKeyDown}
        themeOverrides={themeOverrides}
        size="md"
      />
    </FormField>
  );
};

export { ProjectCodeField };
