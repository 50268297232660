import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { LayoutProps, motion } from 'framer-motion';

import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { useOnMount } from '@float/libs/hooks/useOnMount';
import { TimeString } from '@float/types/datesManager';
import { RepeatState } from '@float/types/repeatState';
import { AllocationType } from '@float/types/task';

import { AllocationByTotalHoursCallout } from '../../components/AllocationByTotalHoursCallout/AllocationByTotalHoursCallout';
import { AllocationTypeSelect } from '../../components/AllocationTypeSelect/AllocationTypeSelect';
import { AllocationTypeToggle } from '../../components/AllocationTypeToggle/AllocationTypeToggle';
import {
  InputAllocationDateRange,
  InputAllocationDateRangeProps,
} from '../../components/InputAllocationDateRange/InputAllocationDateRange';
import { InputAllocationDateRangeHeader } from '../../components/InputAllocationDateRangeHeader/InputAllocationDateRangeHeader';
import {
  InputAllocationHours,
  InputAllocationHoursProps,
} from '../../components/InputAllocationHours/InputAllocationHours';
import { InputAllocationHoursHeader } from '../../components/InputAllocationHoursHeader/InputAllocationHoursHeader';
import {
  InputAllocationPercentage,
  InputAllocationPercentageProps,
} from '../../components/InputAllocationPercentage/InputAllocationPercentage';
import { InputAllocationPercentageHeader } from '../../components/InputAllocationPercentageHeader/InputAllocationPercentageHeader';
import { InputAllocationRepeat } from '../../components/InputAllocationRepeat';
import {
  InputAllocationTimeRange,
  InputAllocationTimeRangeProps,
} from '../../components/InputAllocationTimeRange/InputAllocationTimeRange';
import { InputAllocationTimeRangeHeader } from '../../components/InputAllocationTimeRangeHeader/InputAllocationTimeRangeHeader';
import { getIsSpecificTimeSet } from '../../EditTaskModal.helpers';
import { AllocationTimeSectionPayload } from '../../EditTaskModal.types';
import {
  getAllocationCorrectedHoursUsingHoursPerDay,
  getAllocationCorrectedHoursUsingHoursTotal,
} from '../../helpers/getAllocationCorrectedHours';
import { useAllocationDaysHelpers } from '../../hooks/useAllocationDaysHelpers';
import { useAllocationRepeatControls } from '../../hooks/useAllocationRepeatControls';
import { useAllocationSettings } from '../../hooks/useAllocationSettings';
import { useAllocationTypeControls } from '../../hooks/useAllocationTypeControls';
import { useIsTotalHoursInputSubdued } from '../../hooks/useIsTotalHoursInputSubdued';

import * as styles from '../../EditTaskModal.css';

export type AllocationTimeSectionProps = {
  endDate: Date;
  hasRecurringExternalCalendar: boolean;
  hoursPerDay: number | null;
  hoursTotal: number | null;
  is24HoursTimeFormat: boolean;
  isIntegrationSyncLocked: boolean;
  isIntervalRepeatable: boolean;
  isReadOnly: boolean;
  layout: LayoutProps['layout'];
  onChange: (data: AllocationTimeSectionPayload) => void;
  offWorkDate: Date | undefined;
  peopleIds: number[];
  repeatState: RepeatState;
  repeatEndDate: Date | null;
  startDate: Date;
  startTime: TimeString | null;
  taskId: number | null;
  allocationType: AllocationType | null;
};

export const AllocationTimeSection = forwardRef<
  HTMLDivElement,
  AllocationTimeSectionProps
>((props, ref) => {
  const {
    allocationType,
    endDate,
    hasRecurringExternalCalendar,
    hoursPerDay,
    hoursTotal,
    is24HoursTimeFormat,
    isIntegrationSyncLocked,
    isIntervalRepeatable,
    isReadOnly,
    layout,
    onChange,
    offWorkDate,
    peopleIds,
    repeatEndDate,
    repeatState,
    startDate,
    startTime,
    taskId,
  } = props;

  const isNewTask = !taskId;

  const isAllocationByPercentageEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.AllocationByAvailabilityPercentage,
  );

  const isAllocationByTimeframeEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.AllocationByTimeframe,
  );

  const { allocationSettings, setAllocationSettings } = useAllocationSettings();

  // If the task allocation type is set, use it as the value;
  // otherwise, use the value from the allocation settings
  const allocationTypeResolved =
    typeof allocationType === 'number'
      ? allocationType
      : offWorkDate
        ? AllocationType.Hours
        : allocationSettings.defaultAllocationType;

  const isSpecificTime = getIsSpecificTimeSet(startTime);
  const isPercentage = allocationType === AllocationType.Percentage;

  // We need to store this in the state, so the fixed end date value doesn't get lost
  const [isFixedEndDate, setIsFixedEndDate] = useState(
    () => allocationTypeResolved === AllocationType.HoursWithFixedDuration,
  );
  const isFixedEndDateToggleValue =
    isSpecificTime || isPercentage ? undefined : isFixedEndDate;

  const {
    getIsWorkDay,
    getNumberOfAllocationDays,
    getNumberOfWorkingDayHours,
    getEndDateFromTotalHours,
  } = useAllocationDaysHelpers({
    peopleIds,
  });

  const numberOfAllocationDays = useMemo(() => {
    return getNumberOfAllocationDays(startDate, endDate, offWorkDate);
  }, [startDate, endDate, offWorkDate, getNumberOfAllocationDays]);

  const numberOfWorkingDayHours = useMemo(() => {
    return getNumberOfWorkingDayHours(startDate, endDate);
  }, [startDate, endDate, getNumberOfWorkingDayHours]);

  const minimumHoursPerDayValue = 0.01;
  const maximumHoursPerDayValue = 24;

  // The total hours might need a re-computation on mount
  useOnMount(() => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
      allocationType: allocationTypeResolved,
    });
  });

  const isTotalHoursInputSubdued = useIsTotalHoursInputSubdued(
    numberOfAllocationDays,
  );

  const getIsDateDisabled = useCallback(
    (date: Moment) => !getIsWorkDay(date.toDate()),
    [getIsWorkDay],
  );

  const {
    isAllocationByHoursPerDay,
    isAllocationByHoursSpecificTime,
    isAllocationByPercentage,
    allocationTypeOptionSelectedValue,
    allocationTypeOptions,
    selectHoursPerDay,
    selectSpecificTime,
    handleAllocationTypeChange,
  } = useAllocationTypeControls({
    allocationType: allocationTypeResolved,
    offWorkDate,
    hoursPerDay: hoursPerDay || 0,
    numberOfAllocationDays,
    numberOfWorkingDayHours,
    startTime,
    peopleIds,
    isFixedEndDate,
    isAllocationByPercentAvailable: true,
    isAllocationByPercentDisabled: typeof offWorkDate !== 'undefined',
    onChange,
    setAllocationSettings,
  });

  const handleHoursPerDayChange: InputAllocationHoursProps['onChangeHoursPerDay'] =
    (hoursPerDay) => {
      onChange({
        hoursPerDay,
      });
    };

  const handleHoursTotalChange: InputAllocationHoursProps['onChangeHoursTotal'] =
    (hoursTotal) => {
      // Update the total hours value
      if (isFixedEndDate) {
        onChange({
          hoursTotal,
        });
      }
      // adjust the date range and update the total hours value
      else {
        const endDateRecomputed = getEndDateFromTotalHours({
          startDate,
          hoursPerDay: hoursPerDay || 0,
          hoursTotal: hoursTotal || hoursPerDay || 0,
        });

        onChange({
          hoursTotal,
          endDate: endDateRecomputed,
        });
      }
    };

  const handleHoursPerDayBlur = () => {
    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleHoursTotalBlur = () => {
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay: Math.min(hoursPerDay || 0, hoursTotal || 0),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    } else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay: Math.min(hoursPerDay || 0, hoursTotal || 0),
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handlePercentageChange: InputAllocationPercentageProps['onChange'] = (
    data,
  ) => {
    const { hoursPerDay, hoursTotal } = data;

    onChange({
      hoursPerDay,
      hoursTotal,
    });
  };

  const handleTimeRangeChange: InputAllocationTimeRangeProps['onChange'] = (
    data,
  ) => {
    const { startTime, hoursPerDay } = data;

    const { hoursPerDayCorrected, hoursTotalCorrected } =
      getAllocationCorrectedHoursUsingHoursPerDay({
        hoursPerDay,
        hoursTotal,
        numberOfAllocationDays,
        minimumHoursPerDayValue,
        maximumHoursPerDayValue,
      });

    onChange({
      startTime,
      hoursPerDay: hoursPerDayCorrected,
      hoursTotal: hoursTotalCorrected,
    });
  };

  const handleDateRangeChange: InputAllocationDateRangeProps['onChange'] = (
    data,
  ) => {
    const { startDate, endDate } = data;

    if (allocationType === AllocationType.Percentage) {
      onChange({
        startDate,
        endDate,
      });

      return;
    }

    const numberOfAllocationDays = getNumberOfAllocationDays(
      startDate,
      endDate,
      offWorkDate,
    );

    // When the end date is fixed we want to correct the hours per day value from total value
    if (isFixedEndDate) {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursTotal({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
    // When the end date is not fixed we want to correct the hours total value from hours per day value
    else {
      const { hoursPerDayCorrected, hoursTotalCorrected } =
        getAllocationCorrectedHoursUsingHoursPerDay({
          hoursPerDay,
          hoursTotal,
          numberOfAllocationDays,
          minimumHoursPerDayValue,
          maximumHoursPerDayValue,
        });

      onChange({
        startDate,
        endDate,
        hoursPerDay: hoursPerDayCorrected,
        hoursTotal: hoursTotalCorrected,
      });
    }
  };

  const handleIsFixedEndDateChange: InputAllocationDateRangeProps['onChangeIsFixedEndDate'] =
    (isFixedEndDate) => {
      const allocationType = isFixedEndDate
        ? AllocationType.HoursWithFixedDuration
        : AllocationType.Hours;

      setIsFixedEndDate(isFixedEndDate);

      onChange({
        allocationType,
      });

      // Preserve the
      if (isNewTask) {
        setAllocationSettings('defaultAllocationType', allocationType);
      }
    };

  const { repeatTimes, handleRepeatEndDateChange, handleRepeatIntervalChange } =
    useAllocationRepeatControls({
      startDate,
      repeatState,
      repeatEndDate,
      onChange,
    });

  const hasAllocationTypeControlInHeader =
    !isReadOnly && isAllocationByPercentageEnabled;

  const hasAllocationTypeControlInFooter = !isReadOnly;

  const hasIntervalSelect =
    isNewTask && !isReadOnly && isAllocationByTimeframeEnabled;

  const allocationTypeSelect = hasAllocationTypeControlInHeader ? (
    <AllocationTypeSelect
      allocationTypeOptionSelectedValue={allocationTypeOptionSelectedValue}
      allocationTypeOptions={allocationTypeOptions}
      handleAllocationTypeChange={handleAllocationTypeChange}
    />
  ) : undefined;

  const allocationTypeToggle = hasAllocationTypeControlInFooter ? (
    <AllocationTypeToggle
      appearence={isAllocationByPercentageEnabled ? 'flay-fill' : 'flue-fill'}
      isAllocationByHoursPerDay={isAllocationByHoursPerDay}
      isAllocationByHoursSpecificTime={isAllocationByHoursSpecificTime}
      hasIcon={isAllocationByPercentageEnabled}
      hasHoursPerDayLabel={isAllocationByPercentageEnabled}
      onSelectHoursPerDay={selectHoursPerDay}
      onSelectHoursSpecificTime={selectSpecificTime}
    />
  ) : null;

  return (
    <motion.div
      layout={layout}
      className={
        isReadOnly
          ? styles.timeSectionReadOnlyWrapper
          : styles.timeSectionWrapper
      }
      ref={ref}
    >
      {isAllocationByHoursPerDay && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationHoursHeader
            allocationTypeSelect={allocationTypeSelect}
          />

          <InputAllocationHours
            hoursPerDay={hoursPerDay}
            hoursTotal={hoursTotal}
            isReadOnly={isReadOnly}
            isTotalHoursInputSubdued={isTotalHoursInputSubdued}
            hasVaryingDailyHours={false}
            hasHoursPerDaySuffix={isAllocationByPercentageEnabled}
            onChangeHoursPerDay={handleHoursPerDayChange}
            onChangeHoursTotal={handleHoursTotalChange}
            onBlurHoursPerDay={handleHoursPerDayBlur}
            onBlurHoursTotal={handleHoursTotalBlur}
          />

          {allocationTypeToggle}
        </div>
      )}

      {isAllocationByHoursSpecificTime && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationTimeRangeHeader
            allocationTypeSelect={allocationTypeSelect}
          />

          <InputAllocationTimeRange
            hoursPerDay={hoursPerDay}
            startTime={startTime}
            isReadOnly={isReadOnly}
            is24HoursTimeFormat={is24HoursTimeFormat}
            onChange={handleTimeRangeChange}
          />

          {allocationTypeToggle}
        </div>
      )}

      {isAllocationByPercentage && (
        <div className={styles.timeSectionColumn}>
          <InputAllocationPercentageHeader
            allocationTypeSelect={allocationTypeSelect}
          />

          <InputAllocationPercentage
            hoursPerDay={hoursPerDay}
            numberOfAllocationDaysInDateRange={numberOfAllocationDays}
            numberOfWorkingDayHoursInDateRange={numberOfWorkingDayHours}
            isReadOnly={isReadOnly}
            onChange={handlePercentageChange}
            onBlurPercentage={() => {}}
            onBlurHoursTotal={() => {}}
          />

          {allocationTypeToggle}
        </div>
      )}

      <div
        className={styles.dateRangeColumn}
        data-callout-id="allocation-by-total-hours-callout"
      >
        <InputAllocationDateRangeHeader
          numberOfAllocationDays={numberOfAllocationDays}
          hasIntervalSelect={hasIntervalSelect}
          startDate={startDate}
          endDate={endDate}
          onChange={handleDateRangeChange}
        />

        <InputAllocationDateRange
          startDate={startDate}
          endDate={endDate}
          isReadOnly={isReadOnly}
          isFixedEndDate={isFixedEndDateToggleValue}
          getIsDateDisabled={getIsDateDisabled}
          onChange={handleDateRangeChange}
          onChangeIsFixedEndDate={handleIsFixedEndDateChange}
        />

        {isIntervalRepeatable && (
          <InputAllocationRepeat
            getIsDateDisabled={getIsDateDisabled}
            hasRecurringExternalCalendar={hasRecurringExternalCalendar}
            isIntegrationSyncLocked={isIntegrationSyncLocked}
            isReadOnly={isReadOnly}
            minRepeatDate={endDate}
            onChangeRepeatEndDate={handleRepeatEndDateChange}
            onChangeRepeatInterval={handleRepeatIntervalChange}
            repeatEndDate={repeatEndDate}
            repeatState={repeatState}
            repeatTimes={repeatTimes}
            startDate={startDate}
          />
        )}
      </div>
      {!isReadOnly && <AllocationByTotalHoursCallout />}
    </motion.div>
  );
});
