import React, { Component } from 'react';
import { connect } from 'react-redux';
import TaskModalActivity from 'activity/components/TaskModalActivity';
import { motion } from 'framer-motion';
import { isEmpty, isEqual, isUndefined } from 'lodash';
import { compose } from 'redux';
import styled, { css } from 'styled-components';

import { ensurePhaseLoaded } from '@float/common/actions';
import { createOneOff, removeOneOff } from '@float/common/actions/oneOffs';
import { ensureProjectLoaded } from '@float/common/actions/projects';
import { fetchStatus } from '@float/common/actions/statuses';
import { fetchTaskMetas } from '@float/common/actions/taskMetas';
import {
  deleteTask,
  fetchLinkedEntities,
  fetchTask,
  insertTask,
  replaceTask,
  updateTask,
} from '@float/common/actions/tasks';
import {
  createTimeoff,
  deleteTimeoff,
  fetchTimeoff,
  insertTimeoff,
  replaceTimeoff,
  updateTimeoff,
} from '@float/common/actions/timeoffs';
import { TASK_EDIT_MODES } from '@float/common/components/Schedule/util/ContextMenu';
import { Access, Rights } from '@float/common/lib/acl';
import {
  formatStatus,
  formatTask,
  formatTimeoff,
} from '@float/common/lib/formatters';
import { NOTES_EXCERPT_LENGTH } from '@float/common/lib/notes';
import { getIs24HoursTimeFormat } from '@float/common/lib/time/getIs24HoursTimeFormat';
import {
  isOwnTask,
  isRequestMode,
  isTimeoffRequest,
  isUserManaged,
} from '@float/common/lib/timeoffRequest';
import { fieldsChanged } from '@float/common/lib/utils';
import { getCompanyPrefs } from '@float/common/selectors/companyPrefs';
import { getLastAllocatedTaskHistory } from '@float/common/selectors/lastAllocatedTask';
import { getTaskMetasOptions } from '@float/common/selectors/taskMetas';
import { getActiveFilters } from '@float/common/selectors/views';
import { PROMPTS } from '@float/constants/prompts';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';
import { logger } from '@float/libs/logger';
import { preventDefaultAndStopPropagation } from '@float/libs/utils/events/preventDefaultAndStopPropagation';
import { TaskStatusEnum } from '@float/types';
import {
  Button,
  EH,
  Modal,
  ModalActions,
  ModalBody,
  ModalHeader,
  withConfirm,
  withSnackbar,
} from '@float/ui/deprecated';
import colors from '@float/ui/deprecated/Theme/colors';
import { createTask } from '@float/web/actions/tasks';
import { getIsLegacyCalendarEvent } from '@float/web/components/taskModals/getIsLegacyCalendarEvent';
import { getTaskModalInitialState } from '@float/web/components/taskModals/getTaskModalInitialState';
import { trackOnboardingPrompt } from '@float/web/OnboardingManager/helpers/analytics';
import {
  getAccessibleProjects,
  getActiveCalendarIntegration,
  getActivePmIntegration,
  getClients,
  getCurrencyProps,
  getCurrentUserRaw,
  getEnd,
  getLastProject,
  getPeopleMap,
  getPhasesMapRaw,
  getPhasesOptions,
  getProjectPhases,
  getProjects,
  getProjectsMap,
  getProjectsOptions,
  getStart,
  getTimeoffTypes,
  getTimeoffTypesMap,
  getUnlockedTaskListProjectsOptions,
  getUser,
  userCanSeeBudgets,
} from '@float/web/selectors';
import { setPromptData } from '@float/web/store/onboardingManager/actions';

import {
  fetchCalendarList,
  fetchExtCalendars,
} from '../../../integrations/actions/calendar';
import modalManagerHoc from '../../../modalManager/modalManagerHoc';
import {
  deleteItem,
  getExportedTaskCopiedFromState,
  save,
} from '../../taskModals/actions/updateTask';
import { getAssignedElem } from '../../taskModals/dom/AssigneesField';
import getDatesAndRepeat from '../../taskModals/dom/datesAndRepeat';
import { getIntegrationSyncedElem } from '../../taskModals/dom/integrationSyncedElem';
import getLinkedTaskHeaderElem from '../../taskModals/dom/linkedTaskHeaderElem';
import { getNotesElem } from '../../taskModals/dom/notesElem';
import { RequestManagersLabel } from '../../taskModals/dom/RequestManagersLabel';
import StatusInput from '../../taskModals/dom/StatusInput';
import {
  getTaskStatusToggleButtons,
  shouldStatusElementsNotRender,
} from '../../taskModals/dom/statusToggleButtons';
import { getTaskActions } from '../../taskModals/dom/taskActions';
import { TaskModeTabs } from '../../taskModals/dom/TaskModeTabs';
import { getTaskNameElem } from '../../taskModals/dom/taskNameElem';
import getTotalHoursOrSpecificTime from '../../taskModals/dom/totalHoursOrSpecificTime';
import { TimeoffRequestHeader } from '../../taskModals/TimeoffRequestHeader';
import { InputReadOnly } from './components/InputReadOnly';
import { getTimeoffApproveRejectHeaderProps } from './components/TimeoffApproveRejectHeader/helpers/getTimeoffApproveRejectHeaderProps';
import { TimeoffApproveRejectHeader } from './components/TimeoffApproveRejectHeader/TimeoffApproveRejectHeader';
import {
  getIsIntervalRepeatable,
  getTotalHoursBlurHandler,
} from './EditTaskModal.helpers';
import { getAllocationCost } from './helpers/getAllocationCost';
import { getDefaultPhase } from './helpers/getDefaultPhase';
import { getDerivedStateFromProps } from './helpers/getDerivedStateFromProps';
import { getModalCenterOffset } from './helpers/getModalCenterOffset';
import { getTaskStatusOnSave } from './helpers/getTaskStatusOnSave';
import { handleTaskMetasUpdate } from './helpers/handleTaskMetasUpdate';
import {
  isPhaseTentative,
  isProjectOrPhaseTentative,
  isProjectTentative,
} from './helpers/isProjectOrPhaseTentative';
import { sanitizeToDateObject } from './helpers/sanitizeToDateObject';
import { validateTask } from './helpers/validateTask';
import { useEditTaskModalController } from './useEditTaskModalController';
import AllocationProjectSection from './views/AllocationProjectSection/AllocationProjectSection';
import { AllocationTaskSection } from './views/AllocationTaskSection/AllocationTaskSection';
import { AllocationTimeSection } from './views/AllocationTimeSection/AllocationTimeSection';
import { StatusTimeSection } from './views/StatusTimeSection/StatusTimeSection';
import { getTimeoffTabContentProps } from './views/TimeoffTabContent/helpers/getTimeoffTabContentProps';
import { TimeoffTabContent } from './views/TimeoffTabContent/TimeoffTabContent';
import { TimeoffTimeSection } from './views/TimeoffTimeSection/TimeoffTimeSection';

const Section = styled(motion.div)`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
`;

const TaskModalHeader = styled(ModalHeader)`
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 32px;
`;

const TimeSection = styled(motion.div)`
  border-radius: 6px;
  display: flex;
  flex-wrap: wrap;

  ${(p) =>
    p.readOnly
      ? css`
          padding: 11px 16px;
          margin: -20px -16px 0;
        `
      : css`
          background-color: ${EH.Colors.Core.Surface.Secondary};
          padding: 11px 16px 21px;
          margin: -20px -16px 16px;
        `}
`;

const LOADER_NAMES = {
  updating: 'updating',
  rejecting: 'rejecting',
};

export class EditTaskModal extends Component {
  constructor(props) {
    super(props);

    const initalMode = this.props.modalSettings?.taskMode || 'task';
    this.timeoffApprovalsEnabled = !!+props.user.timeoff_approvals;
    this.state = {
      taskMode: initalMode,
      errors: [],
      notes: '',
      status_note: '',
      taskName: undefined,
      repeatTimes: 1,
      user: {},
      timeExpanded: false,
      // timeoffBalancePreviewDate: null,
      assignedElemErrors: [],
      taskIdsToUnlink: [], // Note: The array element order matters here
      isFirstTask: false,
      animations: true,
      projectsTasksLoading: null,
      hoursPd: 0,
      isEndTimeManuallyChanged: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    return getDerivedStateFromProps(props, state);
  }

  async updateTaskMetas(projectId, phaseId, state = this.state) {
    if (!this.isTaskMetasLoaded(projectId)) {
      this.setState({
        projectsTasksLoading: projectId,
        skipAddTaskFade: false,
      });
    }

    await this.props.fetchTaskMetas(projectId);

    if (this.state.projectsTasksLoading === projectId) {
      this.setState({ projectsTasksLoading: null });
    }

    this.setState(
      handleTaskMetasUpdate(
        this.getTaskMetas({ projectId, phaseId }, state),
        projectId,
        phaseId,
        this.props,
        state,
      ),
    );
  }

  isTaskMetasLoaded(projectId) {
    return !projectId || this.props.taskMetasLoadState[projectId] === 'LOADED';
  }

  isProjectsTasksLoading() {
    return this.state.projectsTasksLoading !== null;
  }

  selectProject = (project) => {
    this.selectProjectPromise = this.setProjectAndLoadTasks(project);
  };

  setProjectAndLoadTasks = async (project) => {
    // When the project changes we must calculate a new default phase
    const { phaseId, isTentative } = this.deriveDefaultPhase(project);

    const prevIsTentative = this.isProjectOrPhaseTentative({
      project: this.project,
    });

    let { status = TaskStatusEnum.Scheduled } = this.props;

    if (isTentative !== prevIsTentative) {
      status = isTentative
        ? TaskStatusEnum.Tentative
        : TaskStatusEnum.Scheduled;
    }

    const prevProjectId = this.state.project?.project_id;
    const prevPhaseId = this.state.phaseId;

    const refreshTaskMetas =
      project.project_id !== prevProjectId || phaseId !== prevPhaseId;

    this.setState({
      project,
      loadingTasksMetas: refreshTaskMetas
        ? {
            prevProjectId,
            prevPhaseId,
          }
        : undefined,
      clientName: project.client_name,
      clientId: project.client_id,
      phaseId,
      status,
      errors: { ...this.props.errors, project: [] },
      forceShowEditTaskField: false,
    });

    if (refreshTaskMetas) {
      await this.updateTaskMetas(project?.project_id, phaseId, this.state);

      this.setState({
        loadingTasksMetas: undefined,
      });
    }
  };

  getTaskMetas(overrides, state = this.state) {
    let projectId = state.project?.project_id;
    let phaseId = state.phaseId;

    const { loadingTasksMetas } = state;

    // Some overrides has been passed, because we want to get the task metas
    // before the project/phase state update
    if (overrides) {
      projectId = overrides.projectId;
      phaseId = overrides.phaseId;
      // The tasks metas are loading, so we use the previous selection to avoid glitches in the UI
      // see https://linear.app/float-com/issue/FT-1454/loading-state-on-project-selection
    } else if (loadingTasksMetas) {
      projectId = loadingTasksMetas.prevProjectId;
      phaseId = loadingTasksMetas.prevPhaseId;
    }

    return getTaskMetasOptions(
      this.props.taskMetas,
      projectId,
      phaseId,
      state.originalTaskUnmodified.name,
    );
  }

  getHasTasksOptions() {
    const taskMetas = this.getTaskMetas();

    return taskMetas.some((taskMetas) => taskMetas.task_name !== '');
  }

  deriveDefaultPhase = (project, state = this.state) => {
    if (!project) return { phaseId: undefined, isTentative: false };

    const { taskOrTimeoff: originalTask, isNewTask } = this.props.modalSettings;

    const { phaseId, phaseManuallyChosen } = getDefaultPhase({
      phaseManuallyChosen: this.phaseManuallyChosen,
      isTimeoffMode: this.isTimeoffMode(),
      projectId: project.project_id,
      phaseId: state.phaseId,
      startDate: state.startDate,
      originalTask,
      isNewTask,
      phasesOptions: this.props.phasesOptions,
    });

    this.phaseManuallyChosen = phaseManuallyChosen;

    const isTentative = this.isProjectOrPhaseTentative(
      {
        project,
        phaseId,
      },
      state,
    );

    return { phaseId, isTentative };
  };

  componentDidUpdate = (prevProps, prevState) => {
    // const copiedTaskFromState = getExportedTaskCopiedFromState.call(this);
    // Enable tentative timeoff state in case of changes
    // TODO: Implement for DnD
    // if (this.isApproveRejectMode() && this.state.status !== 1 && this._previousTask) {
    //   const changesMade = getAllChangedFields(
    //     copiedTaskFromState,
    //     this._previousTask,
    //   ).filter(key => !['full_day', 'status'].includes(key));

    //   if (changesMade.length) {
    //     this.setState({ status: 1 });
    //     this._previousTask = copiedTaskFromState;
    //     return;
    //   }
    // }
    // this._previousTask = copiedTaskFromState;

    // if user is coming from the onboarding 'AddTask' prompt
    if (this.props.isFirstTask && !this.state.isFirstTask) {
      // and show 'Select a task'
      this.setState({ isFirstTask: true });

      // track amplitude event
      trackOnboardingPrompt(PROMPTS.addFirstTask, { action: 'opened' }, 2);
    }

    if (this.state.timeoffTypeId && !prevState.timeoffTypeId) {
      const timeoffType = this.props.timeoffTypesMap[this.state.timeoffTypeId];
      this.setState({ timeoffType });
    }

    if (
      featureFlags.isFeatureEnabled(
        FeatureFlag.EditTaskModalAssigneesFieldTotalHoursUpdate,
      )
    ) {
      return;
    }

    const totalHoursBlurHandler = getTotalHoursBlurHandler(
      this.props.getMinWorkHoursInRange,
      this.props.dates,
      this.props.calcEntityEndDate,
      this.props.calcEntityLength,
      this.isTimeoff(),
      this.state,
      this.setState.bind(this),
    );

    if (
      !isUndefined(prevState.peopleIds) &&
      this.state.peopleIds.length &&
      !isEqual(prevState.peopleIds, this.state.peopleIds)
    ) {
      totalHoursBlurHandler();
    }
  };

  getIsLegacyCalendarEvent = () => {
    return getIsLegacyCalendarEvent({
      extCalendar: this.props.extCalendar,
      originalTask: this.state.originalTaskUnmodified,
    });
  };

  taskRef = React.createRef();

  timeSectionRef = React.createRef();

  focusFirstElOnMount(state) {
    if (this.isTaskMode(state) && !this.isReadOnly(state)) {
      this.timeSectionRef.current?.querySelector('input')?.focus();
    }
  }

  focusTasksRef() {
    this.taskRef.current?.focus();
  }

  deleteItem = deleteItem.bind(this);

  getInitialState(item) {
    const { isNewTask, insert, mode } = this.props.modalSettings;

    this.setCanEditFromTask(item);

    // Using the state update here to not rely on timing hacks to get this.state updated
    let state = getTaskModalInitialState.call(
      this,
      item,
      isNewTask,
      insert,
      mode,
    );

    if (this.isTaskMode(state) && !this.props.isSharedLink) {
      const projectId = state.project?.project_id;

      if (this.isTaskMetasLoaded(projectId)) {
        state = {
          ...state,
          initialSyncDone: true,
          skipAddTaskFade: true,
        };
      }

      if (isNewTask) {
        // When the user is creating a new task we pick the current phase as the default one
        const { phaseId, isTentative } = this.deriveDefaultPhase(
          state.project,
          state,
        );

        // in case the task metas are already in cache, set the default task
        if (this.isTaskMetasLoaded(projectId)) {
          state = {
            ...state,
            ...handleTaskMetasUpdate(
              this.getTaskMetas({ projectId, phaseId }, state),
              projectId,
              phaseId,
              this.props,
              state,
            ),
          };
        }

        return {
          ...state,
          phaseId,
          status: isTentative
            ? TaskStatusEnum.Tentative
            : TaskStatusEnum.Scheduled,
        };
      }
    }

    return state;
  }

  getAllocationCost(
    allocationHours,
    allocationProjectId,
    allocationPhaseId,
    allocationTaskMetaId,
  ) {
    const allocationHoursResolved =
      allocationHours || this.props.calcEntityTotalHours(this.asEntity());

    const projectId =
      typeof allocationProjectId !== 'undefined'
        ? allocationProjectId
        : this.state.project?.project_id;
    const phaseId =
      typeof allocationPhaseId !== 'undefined'
        ? allocationPhaseId
        : this.state.phaseId;
    const taskMetaId =
      typeof allocationTaskMetaId !== 'undefined'
        ? allocationTaskMetaId
        : this.state.taskMetaId;

    const taskMeta = this.props.taskMetas[projectId]?.find(
      (item) => item.task_meta_id === taskMetaId,
    );
    const project = this.props.projectsMap[projectId];
    const phase = this.props.phasesMap[phaseId];

    const peopleMap = this.props.peopleMap;
    const rolesMap = this.props.rolesMap;

    const allocationCost = getAllocationCost(allocationHoursResolved, {
      peopleMap,
      rolesMap,
      taskMeta,
      project,
      phase,
    });

    return allocationCost;
  }

  componentDidMount = async () => {
    const { taskOrTimeoff, isNewTask } = this.props.modalSettings;

    let item;
    try {
      item = await this.ensureItemLoaded(taskOrTimeoff);
    } catch (error) {
      logger.error('Failed to open the Allocation modal', error);

      // if the item fails to load, we should automatically hide the modal
      // https://linear.app/float-com/issue/BDG-1315
      this.hide();
      return;
    }

    const state = this.getInitialState(item);

    this.setState(state, () => {
      this.focusFirstElOnMount(state);
    });

    if (this.isTaskMode(state) && !this.props.isSharedLink) {
      const projectId = state.project?.project_id;
      const phaseId = state.phaseId;

      if (isNewTask) {
        await this.updateTaskMetas(projectId, state.phaseId, state);
      } else {
        await this.props.fetchTaskMetas(projectId);
      }

      if (projectId) {
        await this.props.ensureProjectLoaded(projectId);
      }

      if (phaseId) {
        await this.props.ensurePhaseLoaded(phaseId);
      }
    }

    this.setState({
      initialSyncDone: true,
    });

    setTimeout(() => {
      this.fetchLinkedTasks();
      this.fetchNotesIfNeeded();
      this.fetchCalendarIfNeeded();
    }, 0);
  };

  ensureItemLoaded = (item) => {
    const loaded = Boolean(item.start_date && item.end_date);
    if (item.task_id && !loaded) {
      return this.props.fetchTask(item.task_id).then(formatTask);
    }
    if (item.timeoff_id && !loaded) {
      return this.props.fetchTimeoff(item.timeoff_id).then((res) => {
        const formatted = formatTimeoff(res);
        formatted.isTimeoff = true;
        return formatted;
      });
    }
    if (item.status_id && (!loaded || !item.people_ids)) {
      return this.props.fetchStatus(item.status_id).then((res) => {
        const formatted = formatStatus(res);
        formatted.isStatus = true;
        formatted.people_ids = [formatted.people_id];
        delete formatted.people_id;
        formatted.status_type_name =
          this.props.statusTypes[formatted.status_type_id]?.status_type_name ||
          'Custom';
        return formatted;
      });
    }
    return Promise.resolve(item);
  };

  fetchLinkedTasks = async () => {
    const { task_id } = this.props.modalSettings.taskOrTimeoff;
    if (this.isTaskMode() && task_id) {
      this.props.fetchLinkedEntities(task_id);
    }
  };

  // full notes have not been fetched yet
  isNotesExcerpt = () => {
    const { taskOrTimeoff, isNewTask } = this.props.modalSettings;
    const { task_id } = taskOrTimeoff;
    if (isNewTask || !task_id) {
      return false;
    }

    const { notes, notesFetched } = this.state;

    return Boolean(
      !notesFetched && notes && notes.length === NOTES_EXCERPT_LENGTH,
    );
  };

  fetchNotesIfNeeded = () => {
    if (this.isNotesExcerpt()) {
      const { taskOrTimeoff } = this.props.modalSettings;
      const { task_id } = taskOrTimeoff;

      this.props.fetchTask(task_id).then((res) => {
        this.setState({ notes: res.notes, notesFetched: true });
      });
    } else {
      this.setState({ notesFetched: true });
    }
  };

  fetchCalendarIfNeeded = () => {
    const { modalSettings, fetchExtCalendars, extCalendar } = this.props;
    const { taskOrTimeoff, isNewTask } = modalSettings;
    const { ext_calendar_id, ext_calendar_event_name } = taskOrTimeoff;
    if (
      isNewTask ||
      !ext_calendar_id ||
      ext_calendar_event_name ||
      (extCalendar && extCalendar.label)
    ) {
      return false;
    }

    fetchExtCalendars({ fetchName: true });
  };

  save = async (e) => {
    preventDefaultAndStopPropagation(e);

    const loadingName = this.isRejecting
      ? LOADER_NAMES.rejecting
      : LOADER_NAMES.updating;

    if (this.state[loadingName]) {
      // We're already submitting. Prevent a duplicate
      return;
    }

    this.setState({ [loadingName]: true });

    // We want to be sure that the project selection has been completed
    // https://linear.app/float-com/issue/CS-1370/projects-set-on-allocation-are-reverting-to-previously-set-project
    if (this.selectProjectPromise) {
      await this.selectProjectPromise;
    }

    setTimeout(() => {
      if (!save.call(this)) {
        this.setState({ [loadingName]: false });
      } else {
        // When leaving the onboarding survey success page
        // we set a `fromOnboardingSurvey` flag that's used to identify
        // users that land on the Schedule coming from the Onboarding survey.
        // Here we are clearing that flag, since we don't need it anymore.
        this.props.userHasSavedFirstTask();
      }
    }, 0);
  };

  approve = (e) => {
    this.isRejecting = false;
    this.isApproving = true;
    this.save(e);
  };

  reject = (e) => {
    this.isRejecting = true;
    this.isApproving = false;
    this.save(e);
  };

  componentWillUnmount = () => {
    // default state is true for enabling users to edit,
    // on click we generate this variable from task, user & people data in show
    // method above and set it on the task modal. So when we hide, we revert it
    // back to true.
    this.setCanEdit(true);

    if (this.props.onHide) {
      this.props.onHide();
    }
  };

  clearSelection = () => {
    if (window.getSelection) {
      window.getSelection().removeAllRanges();
    } else if (document.selection) {
      document.selection.empty();
    }
  };

  hide = (e) => {
    preventDefaultAndStopPropagation(e);

    if (this.state.isFirstTask) {
      trackOnboardingPrompt(PROMPTS.addFirstTask, { action: 'closed' }, 2);
    }

    this.props.manageModal({
      visible: false,
      modalType: 'taskModal',
    });

    // This is to fix issue of firefox / safari selecting text on grid if modal is closed using keyboard interactions
    //  (enter or escape) - https://app.asana.com/0/1124931775288939/1125264783172628/f
    setTimeout(this.clearSelection, 100);
  };

  hasChanges() {
    const { isNewTask, task: previousTask } = this.state;
    return (
      isNewTask ||
      fieldsChanged(
        getExportedTaskCopiedFromState.call(this, { ...this.state.task }, true),
        previousTask,
      )
    );
  }

  hideAfterCheckingForChanges = (e) => {
    preventDefaultAndStopPropagation(e);
    if (this.state.readOnly) {
      this.hide();
      return;
    }

    const changesMade = this.hasChanges();
    if (!changesMade) {
      this.hide();
    }

    this.props.confirm({
      title: 'You have unsaved changes.',
      message: 'Are you sure you want to leave?',
      confirmLabel: 'Leave',
      cancelLabel: 'Cancel',
      onConfirm: this.hide,
    });
  };

  validateTimeoff = () => {
    const errors = {};
    const { peopleIds, hoursPd } = this.state;

    if (!hoursPd || hoursPd === 0) {
      errors.hoursPd = [''];
    }

    if (!peopleIds || !peopleIds.length) {
      errors.assignedElem = ['Please select at least one person'];
    }

    if (peopleIds.some((id) => this.props.peopleMap[id].people_type_id == 3)) {
      errors.assignedElem = ['"Placeholder" persons cannot have timeoffs'];
    }

    if (!isEmpty(errors)) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  validateTask = () => {
    const { peopleIds, project, hoursPd, taskName, allocationType } =
      this.state;

    const errors = validateTask(this.getTaskMetas(), {
      peopleIds,
      project,
      hoursPd,
      taskName,
      allocationType,
    });

    if (errors) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  validateStatus = () => {
    const errors = {};
    if (!this.state.statusText) {
      errors.statusText = ['Please enter a status'];
    }

    if (this.state.peopleIds.length === 0) {
      errors.assignedElem = ['Please select at least one person'];
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return false;
    }

    return true;
  };

  validate = () => {
    if (this.state.taskMode === 'task') {
      return this.validateTask();
    }
    if (this.isStatusMode()) {
      return this.validateStatus();
    }
    return this.validateTimeoff();
  };

  saveOnEnterKeyPress = () => {
    setTimeout(this.save, 0);
  };

  setCanEdit(canEdit) {
    this.canEdit = canEdit;
  }

  // TODO: Deprecate this method in favor of serena's entityEditable function
  setCanEditFromTask(data = {}) {
    const task = data.task || data;
    if (data.forceReadOnly) {
      return this.setCanEdit(false);
    }

    const { isNewTask } = this.props.modalSettings;
    if (isNewTask) {
      return this.setCanEdit(true);
    }

    const { user, phasesMap, projectsMap } = this.props;
    const people = task.people_ids.map((id) => this.props.peopleMap[id]);

    const canViewEveryAssignees = Rights.canViewPeople(user, { people });
    if (!canViewEveryAssignees) {
      return false;
    }

    const isStatus = task.status_id;
    if (isStatus) {
      return this.setCanEdit(
        Rights.canUpdateStatus(user, {
          entity: task,
          people,
        }),
      );
    }

    const phase = task.phase_id && phasesMap[task.phase_id];
    const project = projectsMap[task.project_id];
    const isTimeoff = task.isTimeoff;
    if (isTimeoff) {
      const timeoffType = this.props.timeoffTypesMap[task.timeoff_type_id];
      return this.setCanEdit(
        Rights.canUpdateTimeoff(user, {
          entity: task,
          timeoffType,
          people,
          project,
          phase,
        }),
      );
    }

    const canEditTask = people.every((pid) => {
      return Rights.canUpdateTask(user, {
        entity: task,
        people: [pid],
        project,
        phase,
      });
    });

    this.setCanEdit(canEditTask);
  }

  isTimeoff(state = this.state) {
    return this.isTimeoffMode(state);
  }

  isTimeoffMode = (state = this.state) => {
    return state.taskMode === 'timeoff';
  };

  isTaskMode = (state = this.state) => {
    return state.taskMode === 'task';
  };

  isStatusMode = (state = this.state) => {
    return state.taskMode === 'status';
  };

  isApproveRejectMode = (state = this.state) => {
    const { user } = this.props;
    const { task } = state;

    if (this.isEditingRequestedTimeoff(state)) {
      return false;
    }

    const isTimeoffRequest = this.isTimeoffRequest(state);
    const isTentative = task.status === 1 && task.status_request;

    if (isTimeoffRequest && isTentative) {
      const personId = task.people_ids[0];
      const person = this.props.peopleMap[personId];
      return Access.canManagePerson(user, { person });
    }

    return false;
  };

  isTimeoffRequest = (task = this.state.task) => {
    return isTimeoffRequest(task, this.props.user, this.isTimeoffMode());
  };

  isOwnTask = (task = this.state.task) => {
    return isOwnTask(task, this.props.user);
  };

  isUserManaged() {
    const { user, peopleMap } = this.props;
    return isUserManaged(user, peopleMap);
  }

  isRequestMode = (task) => {
    return isRequestMode(
      task ?? this.state.task,
      this.props.user,
      this.props.peopleMap,
      this.isTimeoffMode(),
    );
  };

  isRequestingTimeoff = (state = this.state) => {
    return this.isRequestMode(state.task) && state.isNewTask;
  };

  isEditingRequestedTimeoff = (state = this.state) => {
    return this.isRequestMode(state.task) && !state.isNewTask;
  };

  isReadOnly = (state = this.state) => state.readOnly;
  isActiveProject = (state = this.state) => state.project?.active;
  allowUpdateTheirTask = () => {
    return (
      this.state.isEditingTheirSchedule &&
      this.isActiveProject() &&
      this.state.status !== 1
    );
  };

  canModifyProjects = () =>
    !this.isReadOnly() && !this.state.isEditingTheirSchedule;

  isAccountOwner = () => {
    return this.props.user.account_type_id === 1;
  };

  isAdmin = () => {
    return this.props.user.account_type_id === 2;
  };

  isMember = () => {
    return this.props.user.account_type_id === 4;
  };

  isProjectOrPhaseTentative = (params, state = this.state) => {
    return isProjectOrPhaseTentative(params, state, this.props);
  };

  isProjectTentative = (params = {}) => {
    return isProjectTentative(params, this.state);
  };

  isPhaseTentative = (params = {}) => {
    return isPhaseTentative(params, this.state, this.props);
  };

  shouldShowSingleSelectDropdown = () => {
    const { taskMode, isOffWork } = this.state;

    if (isOffWork) return true;

    const singleSelectFields = ['status'];
    const isSingleSelectField = singleSelectFields.includes(taskMode);
    return isSingleSelectField;
  };

  stopPropagation = (evt) => {
    evt.stopPropagation();
  };

  anchorButtonText = () => {
    if (this.state.mode === TASK_EDIT_MODES.INSERT) {
      return 'Insert';
    }
    if (this.state.mode === TASK_EDIT_MODES.REPLACE) {
      return 'Replace';
    }

    if (this.isRequestingTimeoff()) {
      return 'Request time off';
    } else if (this.isEditingRequestedTimeoff()) {
      return 'Update request';
    }

    if (this.isApproveRejectMode()) {
      return 'Approve';
    }

    if (this.state.isNewTask) {
      if (this.isTimeoffMode()) {
        return 'Create time off';
      }

      if (this.isStatusMode()) {
        return 'Create status';
      }

      return 'Create allocation';
    }
    return 'Update';
  };

  deleteTask = (e) => {
    e?.preventDefault();
    this.deleteItem();
  };

  onStatusChange = ({ text, selectedId, color }) => {
    this.setState({
      statusText: text,
      statusTypeId: selectedId,
      statusColor: color,
      errors: { ...this.state.errors, statusText: [] },
    });
  };

  renderReadOnlyInput = (label, value, props = {}) => {
    return <InputReadOnly label={label} value={value} {...props} />;
  };

  getModalHeaderColor = () => {
    const { integrationSynced, integrationSyncLocked } = this.state;
    return integrationSynced && !integrationSyncLocked
      ? colors.orange
      : undefined;
  };

  getModalCenterOffsetX = () => {
    return getModalCenterOffset({
      sidePanelOpen: this.props.sidePanelOpen,
      screenWidth: window.innerWidth,
    });
  };

  moveToEditAfterPreview = () => {
    this.setState({
      isPreviewingBeforeEdit: false,
      readOnly: false,
    });
  };

  setTimeoffBalancePreviewDate = (timeoffBalancePreviewDate) => {
    this.setState({ timeoffBalancePreviewDate });
  };

  getExportedTaskCopiedFromState = (task) =>
    getExportedTaskCopiedFromState.call(this, task);

  getStatusOnPhaseChange = (phaseId) => {
    const { taskOrTimeoff, isNewTask } = this.props.modalSettings;
    if (!isNewTask && taskOrTimeoff.phase_id === phaseId) {
      return taskOrTimeoff.status;
    }

    if (phaseId) {
      return this.isPhaseTentative({ phaseId }) ? 1 : 2;
    }

    const fallbackStatus = this.state.status;
    return this.isProjectTentative() ? 1 : fallbackStatus;
  };

  getStatusOnSave = (task) => {
    const { phasesMap } = this.props;
    const phase = task.phase_id ? phasesMap[task.phase_id] : undefined;
    return getTaskStatusOnSave(this.state.status, task.project, phase);
  };

  setPhaseId = (phaseId) => {
    const prevStatus = this.state.status;
    const status = this.getStatusOnPhaseChange(phaseId);

    const didStatusChange = status !== prevStatus;
    const didPhaseIdChange = phaseId !== this.state.phaseId;

    let newState;

    if (didStatusChange) {
      newState = Object.assign({}, newState, { status });
    }

    if (didPhaseIdChange) {
      // Ensure that the phase is loaded with expanded team rates
      this.props.ensurePhaseLoaded(phaseId);
    }

    if (didPhaseIdChange && this.state.project) {
      const projectId = this.state.project.project_id;

      newState = Object.assign({}, newState, {
        phaseId,
        forceShowEditTaskField: false,
        status,
        ...handleTaskMetasUpdate(
          this.getTaskMetas({
            projectId,
            phaseId,
          }),
          projectId,
          phaseId,
          this.props,
          this.state,
        ),
      });
    }

    if (newState) this.setState(newState);
  };

  forceShowTaskField = () => {
    this.setState(
      {
        forceShowEditTaskField: true,
      },
      () => {
        this.focusTasksRef();
      },
    );
  };

  isLayoutAnimationEnabled = (layoutAnimationValue) => {
    return this.state.animations ? layoutAnimationValue || true : false;
  };

  enableAnimations = () => {
    this.setState({ animations: true });
  };

  disableAnimations = () => {
    this.setState({ animations: false });
  };

  setEndTimeManuallyChanged = (isEndTimeManuallyChanged) => {
    this.setState({ isEndTimeManuallyChanged });
  };

  render() {
    const isBudgetByTaskFeatureEnabled = featureFlags.isFeatureEnabled(
      FeatureFlag.ProjectBudgetByTasks,
    );

    const isAllocationByTotalHoursFeatureEnabled =
      featureFlags.isFeatureEnabled(FeatureFlag.AllocationByTotalHours);

    const {
      task,
      integrationSynced,
      integrationSyncLocked,
      isLinkedTask,
      isPreviewingBeforeEdit,
    } = this.state;

    const { user, timeoffTypesMap } = this.props;
    const timeoffType = timeoffTypesMap[task?.timeoff_type_id];
    const canPreviewBeforeEdit =
      isPreviewingBeforeEdit &&
      Rights.canUpdateTimeoff(user, {
        entity: task,
        timeoffType,
      });

    if (!task) {
      return null;
    }

    const readOnly = this.isReadOnly();
    const isStatusMode = this.isStatusMode();
    const isTimeSectionReadOnly = readOnly || integrationSyncLocked;

    const startDate = sanitizeToDateObject(this.state.startDate);
    const endDate = sanitizeToDateObject(this.state.endDate);
    const offWorkDate = this.state.isOffWork
      ? sanitizeToDateObject(this.state.isOffWork)
      : undefined;
    const repeatEndDate = sanitizeToDateObject(this.state.repeatEnd);

    let headerElement;
    let headerPaddingLeft = null;
    if (this.isApproveRejectMode()) {
      headerElement = (
        <TimeoffRequestHeader
          senderAccountId={this.state.task.created_by}
          requested={this.state.task.created ?? this.state.task.modified}
        />
      );
    } else if (integrationSynced) {
      headerElement = getIntegrationSyncedElem(this);
    } else if (isLinkedTask) {
      headerElement = getLinkedTaskHeaderElem(this);
    } else {
      // Needed to get the task modes switch alignment right
      headerPaddingLeft = 24;
      headerElement = (
        <TaskModeTabs
          form={this.state}
          {...this.props}
          onTabChange={(tabsName) => {
            const { prevTaskStatus, status } = this.state;

            if (tabsName === 'task') {
              this.setState({
                taskMode: 'task',
                status: prevTaskStatus ?? status,
              });
            }

            if (tabsName === 'status') {
              this.setState({ taskMode: 'status' });
            }

            if (tabsName === 'timeoff') {
              // Need to reset hoursPd, remove startTime and endTime
              const hoursPerDay =
                Number(this.state.task.hours_pd) ||
                Number(this.state.task.hours);

              this.setState(() => {
                const newState = {
                  taskMode: 'timeoff',
                  status: 2,
                  prevTaskStatus: status,
                  editingTimeOfDay: false,
                  hoursPd: hoursPerDay,
                  isFullDay: true,
                  startTime: '',
                  endTime: '',
                };
                return newState;
              });
            }
          }}
        />
      );
    }

    const handleSetPhaseId = ({ value }) => {
      this.phaseManuallyChosen = true;
      this.setPhaseId(Number(value));
    };

    const handleTimeSectionUpdate = (data) => {
      const {
        allocationType,
        startDate,
        startTime,
        endDate,
        hoursPerDay,
        hoursTotal,
        repeatState,
        repeatEndDate,
        isFullDay,
      } = data;

      this.setState((state) => {
        return {
          ...state,
          allocationType: allocationType ?? state.allocationType,
          hoursPd:
            typeof hoursPerDay !== 'undefined' ? hoursPerDay : state.hoursPd,
          totalHours:
            typeof hoursTotal !== 'undefined' ? hoursTotal : state.totalHours,
          startDate: startDate ?? state.startDate,
          startTime:
            typeof startTime !== 'undefined' ? startTime : state.startTime,
          endDate: endDate ?? state.endDate,
          repeatState: repeatState ?? state.repeatState,
          repeatEnd: repeatEndDate ?? state.repeatEnd,
          // TODO: move is full day computation here
          // https://linear.app/float-com/issue/BB-35/allocationtimesection-further-improvements#heading-4f9ff105
          isFullDay: isFullDay ?? state.isFullDay,
        };
      });
    };

    const hasRecurringExternalCalendar = Boolean(
      this.state.task.ext_calendar_recur_id,
    );

    return (
      <Modal
        isOpen
        centerOffsetX={this.getModalCenterOffsetX()}
        onClose={this.hide}
        onBgClick={this.hideAfterCheckingForChanges}
        layoutAnimation={this.isLayoutAnimationEnabled()}
      >
        <form
          noValidate
          onSubmit={this.isApproveRejectMode() ? this.approve : this.save}
        >
          <TaskModalHeader
            // TODO: earhart - integrations bg?
            // color={this.getModalHeaderColor()}
            style={{ paddingLeft: headerPaddingLeft }}
            onClose={this.hide}
            withCloseIcon={this.isApproveRejectMode() || canPreviewBeforeEdit}
          >
            <motion.div
              layout={this.isLayoutAnimationEnabled('preserve-aspect')}
            >
              {headerElement}
            </motion.div>
          </TaskModalHeader>
          <ModalBody>
            {isAllocationByTotalHoursFeatureEnabled && this.isTaskMode() && (
              <AllocationTimeSection
                ref={this.timeSectionRef}
                allocationType={this.state.allocationType}
                endDate={endDate}
                hasRecurringExternalCalendar={hasRecurringExternalCalendar}
                hoursPerDay={this.state.hoursPd}
                hoursTotal={this.state.totalHours}
                is24HoursTimeFormat={this.props.is24HoursTimeFormat}
                isIntegrationSyncLocked={integrationSyncLocked}
                isIntervalRepeatable={getIsIntervalRepeatable(this.state)}
                isReadOnly={isTimeSectionReadOnly}
                layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                onChange={handleTimeSectionUpdate}
                offWorkDate={offWorkDate}
                peopleIds={this.state.peopleIds}
                repeatEndDate={repeatEndDate}
                repeatState={this.state.repeatState}
                repeatTimes={this.state.repeatTimes}
                startDate={startDate}
                startTime={this.state.startTime}
                taskId={this.state.task.task_id}
              />
            )}

            {isAllocationByTotalHoursFeatureEnabled && this.isTimeoffMode() && (
              <TimeoffTimeSection
                ref={this.timeSectionRef}
                endDate={endDate}
                hasRecurringExternalCalendar={hasRecurringExternalCalendar}
                hoursPerDay={this.state.hoursPd}
                hoursTotal={this.state.totalHours}
                is24HoursTimeFormat={this.props.is24HoursTimeFormat}
                isIntegrationSyncLocked={integrationSyncLocked}
                isIntervalRepeatable={getIsIntervalRepeatable(this.state)}
                isReadOnly={isTimeSectionReadOnly}
                isApproveRejectMode={this.isApproveRejectMode()}
                layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                onChange={handleTimeSectionUpdate}
                peopleIds={this.state.peopleIds}
                repeatEndDate={repeatEndDate}
                repeatState={this.state.repeatState}
                repeatTimes={this.state.repeatTimes}
                startDate={startDate}
                startTime={this.state.startTime}
                taskTimeoffId={this.state.task.timeoff_id}
                timeoffId={this.state.task.timeoff_id}
                timeoff={this.state.task}
              />
            )}

            {isAllocationByTotalHoursFeatureEnabled && this.isStatusMode() && (
              <StatusTimeSection
                ref={this.timeSectionRef}
                endDate={endDate}
                hasRecurringExternalCalendar={hasRecurringExternalCalendar}
                isIntegrationSyncLocked={integrationSyncLocked}
                isIntervalRepeatable={getIsIntervalRepeatable(this.state)}
                isReadOnly={isTimeSectionReadOnly}
                layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                onChange={handleTimeSectionUpdate}
                peopleIds={this.state.peopleIds}
                repeatEndDate={repeatEndDate}
                repeatState={this.state.repeatState}
                repeatTimes={this.state.repeatTimes}
                startDate={startDate}
              />
            )}

            {/* Legacy component */}
            {!isAllocationByTotalHoursFeatureEnabled && (
              <TimeSection
                ref={this.timeSectionRef}
                readOnly={isTimeSectionReadOnly}
                layout={this.isLayoutAnimationEnabled('preserve-aspect')}
              >
                {this.isApproveRejectMode() && (
                  <TimeoffApproveRejectHeader
                    {...getTimeoffApproveRejectHeaderProps(this)}
                  />
                )}

                {!isStatusMode &&
                  getTotalHoursOrSpecificTime(this, this.props.companyPrefs)}
                {getDatesAndRepeat(this)}
              </TimeSection>
            )}

            {this.isTaskMode() && (
              <>
                <Section
                  layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                >
                  <AllocationProjectSection
                    initialSyncDone={this.state.initialSyncDone}
                    showNoTasksLine={!this.getHasTasksOptions()}
                    project={this.state.project}
                    isReadOnly={this.isReadOnly}
                    user={this.props.user}
                    projectPhases={this.props.projectPhases}
                    phaseId={this.state.phaseId}
                    setPhaseId={handleSetPhaseId}
                    phasesOptions={this.props.phasesOptions}
                    projectsMap={this.props.projectsMap}
                    integrationSyncLocked={integrationSyncLocked}
                    hasError={!isEmpty(this.state.errors)}
                    manageModal={this.props.manageModal}
                    updateModal={this.props.updateModal}
                    status={this.state.status}
                    errors={this.state.errors.project}
                    projectsOptions={this.props.projectsOptions}
                    projectsFilteredOptions={this.props.projectsFilteredOptions}
                    isLegacyCalendarEvent={this.getIsLegacyCalendarEvent()}
                    isProjectOrPhaseTentative={this.isProjectOrPhaseTentative}
                    selectProject={this.selectProject}
                    task={this.state.task}
                    taskMode={this.state.taskMode}
                    initialHours={this.state.initialHours}
                    calcEntityTotalHours={this.props.calcEntityTotalHours}
                    peopleMap={this.props.peopleMap}
                    isBudgetByTaskFeatureEnabled={isBudgetByTaskFeatureEnabled}
                    userCanSeeBudgets={this.props.userCanSeeBudgets}
                    allocationCost={this.getAllocationCost()}
                    allocationCostInitial={this.getAllocationCost(
                      this.state.initialHours,
                      this.state?.originalTaskUnmodified?.project_id,
                      this.state?.originalTaskUnmodified?.phase_id,
                      this.state?.originalTaskUnmodified?.task_meta_id,
                    )}
                    budgets={this.props.budgets}
                    currencyProps={this.props.currencyProps}
                    toggleForceShowTaskField={this.forceShowTaskField}
                    asEntity={this.asEntity}
                    forceShowEditTaskField={this.state.forceShowEditTaskField}
                    loading={this.isProjectsTasksLoading()}
                    skipAddTaskFade={this.state.skipAddTaskFade}
                    hasChanges={this.hasChanges.bind(this)}
                    onClose={this.hide.bind(this)}
                  />
                </Section>
                <Section
                  layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                >
                  {isBudgetByTaskFeatureEnabled ? (
                    <AllocationTaskSection
                      ref={this.taskRef}
                      allocationCost={this.getAllocationCost()}
                      allocationCostInitial={this.getAllocationCost(
                        this.state.initialHours,
                        this.state?.originalTaskUnmodified?.project_id,
                        this.state?.originalTaskUnmodified?.phase_id,
                        this.state?.originalTaskUnmodified?.task_meta_id,
                      )}
                      budgets={this.props.budgets}
                      currencyProps={this.props.currencyProps}
                      pmUrl={this.state.pmUrl}
                      pmIntegrationType={this.props.pmIntegrationType}
                      project={this.state.project}
                      errors={this.state.errors}
                      integrationSyncLocked={integrationSyncLocked}
                      isFirstTask={this.state.isFirstTask}
                      taskName={this.state.taskName}
                      taskMetas={this.getTaskMetas()}
                      taskMetaId={this.state.taskMetaId}
                      userCanSeeBudgets={this.props.userCanSeeBudgets}
                      shouldStatusElementsNotRender={shouldStatusElementsNotRender(
                        this,
                      )}
                      forceShowEditTaskField={this.state.forceShowEditTaskField}
                      setState={this.setState.bind(this)}
                      isReadOnly={this.isReadOnly}
                      renderReadOnlyInput={this.renderReadOnlyInput}
                      getIsLegacyCalendarEvent={this.getIsLegacyCalendarEvent}
                      onEnter={this.saveOnEnterKeyPress}
                    />
                  ) : (
                    getTaskNameElem(this)
                  )}
                </Section>
                <Section
                  layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                >
                  {getTaskStatusToggleButtons(this)}
                </Section>
                {!isStatusMode && (
                  // The RichText component doesn't work well with the layout animations
                  // so we turn off them during editing
                  <Section
                    layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                    onFocus={this.disableAnimations}
                    onBlur={this.enableAnimations}
                  >
                    {getNotesElem(this)}
                  </Section>
                )}
                {!this.isApproveRejectMode() && (
                  <Section
                    layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                  >
                    {getAssignedElem(this)}
                  </Section>
                )}
              </>
            )}

            {this.isTimeoffMode() && (
              <TimeoffTabContent {...getTimeoffTabContentProps(this)} />
            )}

            {isStatusMode && (
              <>
                <Section
                  layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                >
                  <StatusInput
                    text={this.state.statusText}
                    options={this.props.statusTypes}
                    errors={this.state.errors.statusText}
                    readOnly={readOnly}
                    onChange={this.onStatusChange}
                    onEnter={this.saveOnEnterKeyPress}
                  />
                </Section>
                {!this.isApproveRejectMode() && (
                  <Section
                    layout={this.isLayoutAnimationEnabled('preserve-aspect')}
                  >
                    {getAssignedElem(this)}
                  </Section>
                )}
              </>
            )}
            {!this.state.isNewTask && !this.isApproveRejectMode() && (
              <motion.div
                layout={this.isLayoutAnimationEnabled('preserve-aspect')}
              >
                <TaskModalActivity
                  task={this.state.task}
                  composed={false}
                  readOnly={readOnly}
                  isPreviewingBeforeEdit={canPreviewBeforeEdit}
                  isStatusMode={isStatusMode}
                />
              </motion.div>
            )}
            {this.isRequestingTimeoff() && (
              <RequestManagersLabel peopleId={this.state.peopleIds[0]} />
            )}
          </ModalBody>
          {(!readOnly ||
            this.allowUpdateTheirTask() ||
            canPreviewBeforeEdit) && (
            <motion.div
              layout={this.isLayoutAnimationEnabled('preserve-aspect')}
            >
              <ModalActions style={{ paddingTop: 16 }}>
                {canPreviewBeforeEdit ? (
                  <Button type="submit" onClick={this.moveToEditAfterPreview}>
                    Edit time off
                  </Button>
                ) : (
                  <>
                    <Button
                      type="submit"
                      loader={this.state[LOADER_NAMES.updating]}
                      disabled={this.isNotesExcerpt()}
                    >
                      {this.anchorButtonText()}
                    </Button>
                    {this.isApproveRejectMode() ? (
                      <Button
                        appearance="danger-outline"
                        onClick={this.reject}
                        loader={this.state[LOADER_NAMES.rejecting]}
                      >
                        Decline
                      </Button>
                    ) : (
                      <Button appearance="secondary" onClick={this.hide}>
                        Cancel
                      </Button>
                    )}
                  </>
                )}
                {getTaskActions(this)}
              </ModalActions>
            </motion.div>
          )}
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { baseUrl, type } = getActivePmIntegration(state);
  const projectsOptions = getProjectsOptions(state);
  const projectsFilteredOptions = getUnlockedTaskListProjectsOptions(state);
  const projectPhases = getProjectPhases(state);

  const user = getUser(state);
  const { prefs: userPrefs } = getCurrentUserRaw(state);
  const companyPrefs = getCompanyPrefs(state);

  const is24HoursTimeFormat = getIs24HoursTimeFormat(userPrefs, companyPrefs);

  return {
    budgets: state.budgets,
    currencyProps: getCurrencyProps(state),
    timeoffs: state.timeoffs.timeoffs,
    tasks: state.tasks.tasks,
    taskMetas: state.taskMetas.taskMetas,
    taskMetasLoadState: state.taskMetas.loadState,
    lastDeletedTaskMetaIds: state.taskMetas.lastDeletedIds,
    timeoffTypesMap: getTimeoffTypesMap(state),
    timeoffTypes: getTimeoffTypes(state),
    statusTypes: state.statusTypes.statusTypes,
    peopleMap: getPeopleMap(state),
    clients: getClients(state),
    companyPrefs: getCompanyPrefs(state),
    projects: getProjects(state),
    accessibleProjects: getAccessibleProjects(state),
    projectsOptions,
    projectsFilteredOptions,
    phasesOptions: getPhasesOptions(state),
    phasesMap: getPhasesMapRaw(state),
    projectsMap: getProjectsMap(state),
    rolesMap: state.roles.roles,
    lastProject: getLastProject(state),
    lastAllocatedTaskHistory: getLastAllocatedTaskHistory(state),
    user,
    projectModalOpen: state.modalManager.projectModal.showing,
    viewStart: getStart(state),
    viewEnd: getEnd(state),
    searchFilters: getActiveFilters(state),
    taskLinksMap: state.pmSidebar.taskLinks,
    pmBaseUrl: baseUrl,
    pmIntegrationType: type,
    extCalendar: getActiveCalendarIntegration(state),
    projectPhases,
    isSharedLink: state.currentUser.shared_link_view,
    userCanSeeBudgets: userCanSeeBudgets(user),
    timeoffApprovalsEnabled: state.companyPrefs.timeoff_approvals,
    isFirstTask:
      state.onboardingManager?.promptData[PROMPTS.onboardingSurveySuccess]
        ?.fromOnboardingSurvey,
    is24HoursTimeFormat,
  };
};

const mapDispatchToProps = (dispatch) => ({
  createTask: (task) => dispatch(createTask(task)),
  updateTask: (task) => dispatch(updateTask(task)),
  deleteTask: (task) => dispatch(deleteTask(task)),
  insertTask: (task, onError) => dispatch(insertTask(task, onError)),
  replaceTask: (task, onError) => dispatch(replaceTask(task, onError)),
  createTimeoff: (timeoff) => dispatch(createTimeoff(timeoff)),
  updateTimeoff: (timeoff) => dispatch(updateTimeoff(timeoff)),
  deleteTimeoff: (timeoff) => dispatch(deleteTimeoff(timeoff)),
  insertTimeoff: (timeoff, onError) =>
    dispatch(insertTimeoff(timeoff, onError)),
  replaceTimeoff: (timeoff, onError) =>
    dispatch(replaceTimeoff(timeoff, onError)),
  createOneOff: (offWorkData) => dispatch(createOneOff(offWorkData)),
  removeOneOff: (offWorkData) => dispatch(removeOneOff(offWorkData)),
  ensureProjectLoaded: (projectId) => dispatch(ensureProjectLoaded(projectId)),
  ensurePhaseLoaded: (phaseId) => dispatch(ensurePhaseLoaded(phaseId)),
  fetchTask: (taskId) => dispatch(fetchTask(taskId)),
  fetchTaskMetas: (projectId) => dispatch(fetchTaskMetas(projectId)),
  fetchTimeoff: (id) => dispatch(fetchTimeoff(id)),
  fetchStatus: (id) => dispatch(fetchStatus(id)),
  fetchCalendarList: (type) => dispatch(fetchCalendarList(type)),
  fetchExtCalendars: ({ fetchName }) =>
    dispatch(fetchExtCalendars({ fetchName })),
  fetchLinkedEntities: (taskId) => dispatch(fetchLinkedEntities(taskId)),
  userHasSavedFirstTask: () =>
    dispatch(setPromptData(PROMPTS.onboardingSurveySuccess, null)),
});

function EditTaskModalWithController(props) {
  const api = useEditTaskModalController();

  return <EditTaskModal {...props} {...api} />;
}

const Comp = compose(withConfirm, withSnackbar)(EditTaskModalWithController);

export const EditTaskModalConnected = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comp);

export default modalManagerHoc({
  Comp: EditTaskModalConnected,
  modalType: 'taskModal',
});
