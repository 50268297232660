import React, { useMemo } from 'react';
import { t } from '@lingui/macro';

import { DropdownSelect } from '@float/ui/deprecated';

import {
  getOptions,
  getOptionSelected,
} from './InputAllocationDateRangeSelect.helpers';
import { InputAllocatationDateRangeSelectOption } from './InputAllocationDateRangeSelect.types';

export type InputAllocationDateRangeSelectProps = {
  startDate: Date;
  endDate: Date;
  onSelect: (data: { startDate: Date; endDate: Date }) => void;
};

export const InputAllocationDateRangeSelect = (
  props: InputAllocationDateRangeSelectProps,
) => {
  const { startDate, endDate, onSelect } = props;

  const options = useMemo(() => getOptions(), []);
  const optionSelected = getOptionSelected(startDate, endDate);

  const handleDateRangeIntervalOptionSelect = (
    option: InputAllocatationDateRangeSelectOption,
  ) => {
    const startDate = option.dateStart ?? new Date();
    const endDate = option.dateEnd ?? new Date();

    onSelect({
      startDate,
      endDate,
    });
  };

  return (
    <DropdownSelect
      appearance="text"
      value={optionSelected.value}
      options={options}
      tickSelectedOption={true}
      tickRight={true}
      onChange={handleDateRangeIntervalOptionSelect}
      ariaLabel={t`Date range interval select`}
    />
  );
};
