import React, { useCallback, useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash';
import styled from 'styled-components';

import { trackIntegrationEvent } from '@float/common/lib/analytics';
import { getUser } from '@float/common/selectors/currentUser';
import { useAppSelectorStrict } from '@float/common/store';
import { capitalize } from '@float/libs/utils/string/capitalize';
import { Button } from '@float/ui/deprecated/Button/Button';
import CheckBox from '@float/ui/deprecated/Checkbox/Checkbox';
import { IconClose } from '@float/ui/deprecated/Earhart/Icons';

import Body from '../../Body';
import {
  DetailList,
  DetailListItem,
  ListItemP,
  ListItemPWarning,
  SubheadingPS,
  SubheadingPSNote,
  SubheadingPSWarning,
  Subsection,
  Text,
} from '../styles';
import DisconnectModal from './DisconnectModal';
import { Section } from './Section';
import SetupModal from './SetupModal';

const Footer = styled.div`
  display: flex;
  padding: 32px;
`;

const Buttons = styled.div`
  margin-left: auto;
`;

const SubsectionWithDetail = styled.div`
  display: flex;
  flex-direction: column;
`;

const PlanCapWarning = styled.div`
  display: flex;
  padding: 12px 20px;
  background-color: #fff2e6;
  border-radius: 5px;
  margin-top: 16px;
  flex-direction: column;
`;

const PlanCapWarningText = styled.p`
  font-weight: regular;
  font-size: 14px;
  line-height: 20px;
  color: #e56f00;
`;

const PlanCapWarningHeading = styled(PlanCapWarningText)`
  font-weight: bold;
`;

const defaultProjectLabelFn = ({ name }) => name;

function Setup(props) {
  const {
    type,
    label,
    preloadData,
    config,
    coInt,
    isUpdating,
    exitSetup,
    removeCoInt,
    updateConfig,
    clearPreload,
    labels = {},
    children,
    appendSubmitConfig,
    projectKeyField,
    projectLabelFn = defaultProjectLabelFn,
    calculateExcludedSelections = false,
    importIssues = true,
    showEstimateSection = false,
  } = props;
  const {
    projects,
    people,
    estimateFields,
    remainingCap,
    matchedPeople = [],
  } = preloadData;
  const isInitStatus = get(coInt, 'status') === 1; // yet to complete setup
  const isSyncingStatus = get(coInt, 'status') === 2; // yet to complete setup
  const isEditMode = coInt && !isInitStatus && !isSyncingStatus;
  const importNotExcluded =
    get(coInt, 'ancillary.errors.API_UNAUTHORIZED', false) ||
    get(coInt, 'ancillary.deactivated', false);

  const user = useAppSelectorStrict(getUser);

  const [importProjects, setImportProjects] = useState(
    isEditMode && config ? !!config.projectChoices.length : true,
  );
  const [projectChoices, setProjectChoices] = useState(
    projects.map((proj) => {
      let shouldImport = true;
      if (isEditMode && config) {
        shouldImport =
          importNotExcluded && config.futureProjects && config.projectsExcluded
            ? !config.projectsExcluded.includes(proj.id)
            : config.projectChoices.includes(proj.id);
      }
      return { ...proj, import: shouldImport };
    }),
  );
  const [importFutureProjects, setImportFutureProjects] = useState(
    isEditMode && config ? config.futureProjects : true,
  );
  const [importPeople, setImportPeople] = useState(
    isEditMode && config ? !!config.peopleChoices.length : true,
  );
  const [peopleChoices, setPeopleChoices] = useState(
    people.map((person) => {
      let shouldImport = true;
      if (isEditMode && config) {
        shouldImport =
          importNotExcluded && config.futurePeople && config.peopleExcluded
            ? !config.peopleExcluded.includes(person.id)
            : config.peopleChoices.includes(person.id);
      }
      return { ...person, import: shouldImport };
    }),
  );
  const [importFuturePeople, setImportFuturePeople] = useState(
    isEditMode && config ? config.futurePeople : true,
  );

  const [estimateField, setEstimateField] = useState(
    isEditMode && config
      ? (estimateFields || []).find((f) => f.key === config.estimateField)
      : null,
  );

  const exit = useCallback(() => {
    clearPreload();
    exitSetup();
  }, [exitSetup, clearPreload]);

  useEffect(() => {
    if (projectChoices.every((pc) => !pc.import)) {
      setImportProjects(false);
    }
  }, [projectChoices]);
  useEffect(() => {
    if (importProjects && projectChoices.every((pc) => !pc.import)) {
      setProjectChoices(projectChoices.map((pc) => ({ ...pc, import: true })));
    }
  }, [importProjects]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (peopleChoices.every((pc) => !pc.import)) {
      setImportPeople(false);
    }
  }, [peopleChoices]);
  useEffect(() => {
    if (importPeople && peopleChoices.every((pc) => !pc.import)) {
      setPeopleChoices(peopleChoices.map((pc) => ({ ...pc, import: true })));
    }
  }, [importPeople]); // eslint-disable-line react-hooks/exhaustive-deps
  const numPeopleChosen = importPeople
    ? peopleChoices.filter((p) => p.import).length
    : 0;
  const [selectModalOpen, setSelectModalOpen] = useState(false);
  const [selectModalProps, setSelectModalProps] = useState({});
  const [disconnectModalOpen, setDisconnectModalOpen] = useState(false);

  function editConfig(type) {
    let props = {};
    switch (type) {
      case 'projects':
        props = {
          title: `Which ${
            labels.projects || 'projects'
          } would you like to connect?`,
          choices: projectChoices,
          setChoices: setProjectChoices,
          allOption: `All ${capitalize(labels.projects || 'projects')}`,
          keyField: projectKeyField,
          labelFn: projectLabelFn,
        };
        break;
      case 'people':
        props = {
          title: `Select ${labels.people || 'people'} to import`,
          choices: peopleChoices,
          setChoices: setPeopleChoices,
          allOption: `All ${capitalize(labels.people || 'people')}`,
        };
        break;
      case 'estimateField':
        props = {
          title: `Which ${label} field holds estimated time?`,
          choices: estimateFields,
          setChoices: (choicesArray) => setEstimateField(choicesArray[0]),
          multiChoice: false,
          defaultValue: estimateField,
          footer: "Ensure you're using Hours as your estimate for time",
        };
        break;
    }
    setSelectModalProps(props);
    setSelectModalOpen(true);
  }

  function onDisconnectClick() {
    if (coInt) {
      setDisconnectModalOpen(true);
    } else {
      exit();
    }
  }

  function trackInitialRecipeSubmission(config) {
    trackIntegrationEvent(
      type,
      'Integration recipe submitted',
      {
        projects: config.projectChoices.length,
        people: config.peopleChoices.length,
        futureProjects: config.futureProjects,
        futurePeople: config.futurePeople,
        estimate: !!config.estimateField,
      },
      user.cid,
    );
  }

  function onEstimateFieldCheckboxChange(checked) {
    if (checked) {
      // open modal
      editConfig('estimateField');
    } else {
      setEstimateField(null);
    }
  }

  function onSubmitClick() {
    const config = {
      projectChoices: importProjects
        ? projectChoices.filter((pc) => pc.import).map((pc) => pc.id)
        : [],
      peopleChoices: importPeople
        ? peopleChoices.filter((pc) => pc.import).map((pc) => pc.id)
        : [],
      futureProjects: importFutureProjects,
      futurePeople: importFuturePeople,
      ...(calculateExcludedSelections && {
        projectsExcluded: importProjects
          ? projectChoices.filter((mc) => !mc.import).map((mc) => mc.id)
          : projectChoices.map((mc) => mc.id),
        peopleExcluded: importPeople
          ? peopleChoices.filter((mc) => !mc.import).map((mc) => mc.id)
          : peopleChoices.map((mc) => mc.id),
      }),
      estimateField: showEstimateSection
        ? estimateField && estimateField.key
        : undefined,
      ...(appendSubmitConfig && appendSubmitConfig()),
    };
    const startInitialImport = !isEditMode;
    updateConfig(coInt, config, { startInitialImport });
    if (!isEditMode) {
      trackInitialRecipeSubmission(config);
    }
    exit();
  }

  const disableButtons = isUpdating || isSyncingStatus;
  const nothingSelected =
    !importProjects &&
    !importPeople &&
    !importFuturePeople &&
    !importFutureProjects;
  const disableConnect = numPeopleChosen > remainingCap || nothingSelected;

  const peopleChoicesToImport = importPeople
    ? peopleChoices.filter((person) => person.import)
    : [];
  const hasMatchingPeople = Object.values(peopleChoices).some(
    (choice) => !isEmpty(choice.matchingPeople),
  );
  const nPeopleImportWithMatch = hasMatchingPeople
    ? peopleChoices.filter((mc) => mc.import && !isEmpty(mc.matchingPeople))
        .length
    : 0;

  const numProjectChoices = projectChoices.filter((pc) => pc.import).length;

  return (
    <Body
      className="flt-account-settings__main__body--api"
      header={
        isEditMode
          ? `Edit ${label} Integration`
          : `Finalize ${label} Integration`
      }
      subheader={`How would you like to connect ${label}?`}
    >
      <Section
        heading={capitalize(labels.projects || 'projects')}
        bottomBreak={false}
        bottomBorder={true}
        noBorder={[true]}
      >
        <SubsectionWithDetail>
          <Subsection style={{ alignItems: 'center', padding: '10px 0' }}>
            <CheckBox
              value={importProjects}
              onChange={() => setImportProjects(!importProjects)}
            />
            <SubheadingPS greyed={!projects.length}>
              Import {importProjects && numProjectChoices}{' '}
              {!importProjects || numProjectChoices > 1
                ? labels.projects || 'projects'
                : labels.project || 'project'}{' '}
              currently in {label}
            </SubheadingPS>
            <Buttons>
              {importProjects && (
                <Button onClick={() => editConfig('projects')}>
                  Select {labels.projects || 'projects'}
                </Button>
              )}
            </Buttons>
          </Subsection>
          {importProjects && (
            <DetailList>
              {projectChoices
                .filter((proj) => proj.import)
                .map((proj) => (
                  <DetailListItem key={proj.id}>
                    <ListItemP>{projectLabelFn(proj)}</ListItemP>
                  </DetailListItem>
                ))}
            </DetailList>
          )}
        </SubsectionWithDetail>
        <Subsection style={{ alignItems: 'center', padding: '10px 0' }}>
          <CheckBox
            value={importFutureProjects}
            onChange={() => setImportFutureProjects(!importFutureProjects)}
          />
          <SubheadingPS>
            Connect {labels.projects || 'projects'} added to {label} in the
            future
          </SubheadingPS>
        </Subsection>
      </Section>
      <Section
        heading={capitalize(labels.people || 'people')}
        bottomBreak={false}
        bottomBorder={!!children || showEstimateSection}
        noBorder={[true, true]}
      >
        <Subsection style={{ padding: '10px 0' }}>
          <div
            style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <div style={{ maxWidth: '700px' }}>
              <Text>
                {capitalize(labels.people || 'people')} will be automatically
                added to your Float schedule
                {importIssues &&
                  ` and matched with their assigned ${capitalize(
                    labels.issues || 'issues',
                  )} to make scheduling easy`}
                . They won’t be invited to use Float just yet, but you can give
                them access at anytime.
              </Text>
            </div>
            {numPeopleChosen > remainingCap && (
              <PlanCapWarning>
                <PlanCapWarningHeading>
                  {isEditMode
                    ? `You have reached the maximum ${
                        labels.people || 'people'
                      } allowed on your current plan`
                    : `You can only import ${remainingCap} of ${
                        peopleChoices.length
                      } ${
                        labels.people || 'people'
                      } in ${label} on your current plan`}
                </PlanCapWarningHeading>
                <PlanCapWarningText>
                  What would you like to do?
                </PlanCapWarningText>
                <div style={{ display: 'flex', margin: '16px 0' }}>
                  <Button
                    disabled={disableButtons}
                    onClick={() => editConfig('people')}
                  >
                    Choose {remainingCap} {labels.people || 'people'}
                  </Button>
                  <Button
                    disabled={disableButtons}
                    style={{ marginLeft: 10 }}
                    onClick={() => window.open('/thankyou')}
                  >
                    Upgrade plan
                  </Button>
                  <Button
                    disabled={disableButtons}
                    style={{ marginLeft: 10 }}
                    onClick={() => setImportPeople(false)}
                  >
                    Don't import anyone
                  </Button>
                </div>
              </PlanCapWarning>
            )}
          </div>
        </Subsection>
        {numPeopleChosen <= remainingCap && !isEditMode && (
          <SubsectionWithDetail>
            <Subsection
              style={{
                alignItems: !people.length ? 'top' : 'center',
                padding: '10px 0',
              }}
            >
              <CheckBox
                value={importPeople}
                readOnly={!people.length}
                onChange={() => setImportPeople(!importPeople)}
              />
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SubheadingPS greyed={!people.length}>
                  Import {importPeople && peopleChoicesToImport.length}{' '}
                  {!importPeople || peopleChoicesToImport.length > 1
                    ? labels.people || 'people'
                    : labels.person || 'person'}{' '}
                  currently in {label}
                </SubheadingPS>
                {!people.length && (
                  <SubheadingPSNote>
                    No new {labels.people || 'people'} to import.{' '}
                    {!!matchedPeople.length &&
                      `${matchedPeople.length} ${
                        matchedPeople.length > 1
                          ? labels.people || 'people'
                          : labels.person || 'person'
                      }
                      in ${label} already exist${
                        matchedPeople.length === 1 ? 's' : ''
                      } in Float.`}
                  </SubheadingPSNote>
                )}
                {importPeople && !!nPeopleImportWithMatch && (
                  <SubheadingPSWarning>
                    {nPeopleImportWithMatch}{' '}
                    {nPeopleImportWithMatch > 1
                      ? labels.people || 'people'
                      : labels.person || 'person'}{' '}
                    may already exist in Float. You can exclude them to avoid
                    duplicates.
                  </SubheadingPSWarning>
                )}
              </div>

              <Buttons>
                {importPeople && (
                  <Button onClick={() => editConfig('people')}>
                    Select {labels.people || 'people'}
                  </Button>
                )}
              </Buttons>
            </Subsection>
            {importPeople && (
              <DetailList>
                {peopleChoicesToImport.map((person) => (
                  <DetailListItem key={person.id}>
                    <ListItemP>{person.name}</ListItemP>
                    {hasMatchingPeople ? (
                      <ListItemPWarning>
                        {!isEmpty(person.matchingPeople) &&
                          'Potential duplicate'}
                      </ListItemPWarning>
                    ) : (
                      <ListItemP>{person.email || ''}</ListItemP>
                    )}
                  </DetailListItem>
                ))}
              </DetailList>
            )}
          </SubsectionWithDetail>
        )}
        <Subsection style={{ alignItems: 'center', padding: '10px 0' }}>
          <CheckBox
            value={importFuturePeople}
            onChange={() => setImportFuturePeople(!importFuturePeople)}
          />
          <SubheadingPS>
            Import {labels.people || 'people'} added to {label} in the future
          </SubheadingPS>
        </Subsection>
      </Section>
      {showEstimateSection && (
        <Section
          heading={capitalize(labels.projects || 'Import Estimated Time')}
          bottomBreak={false}
          bottomBorder={false}
          noBorder={[true, true]}
        >
          <Subsection style={{ padding: '10px 0' }}>
            <Text>
              Populate the tasks you import with estimated time from {label}
            </Text>
          </Subsection>
          <Subsection style={{ padding: '10px 0' }}>
            <CheckBox
              value={Boolean(estimateField)}
              onChange={onEstimateFieldCheckboxChange}
            />
            <SubheadingPS>
              Import estimated time
              {estimateField ? ` from "${estimateField.name}" field` : ''}
            </SubheadingPS>
            <Buttons>
              {estimateField && (
                <Button onClick={() => editConfig('estimateField')}>
                  Change estimate field
                </Button>
              )}
            </Buttons>
          </Subsection>
        </Section>
      )}
      {children}
      <Footer>
        <Button
          onClick={onSubmitClick}
          disabled={disableButtons || disableConnect}
          loader={isSyncingStatus}
        >
          {isEditMode ? 'Save' : 'Import'}
        </Button>
        <Button
          onClick={exit}
          appearance="secondary"
          style={{ marginLeft: '10px' }}
          disabled={disableButtons}
        >
          Cancel
        </Button>
        <Button
          appearance="danger"
          style={{ marginLeft: 'auto' }}
          iconRight={IconClose}
          onClick={onDisconnectClick}
          disabled={disableButtons}
        >
          Disconnect {label}
        </Button>
      </Footer>
      {selectModalOpen && (
        <SetupModal
          {...selectModalProps}
          keyField={projectKeyField}
          labelFn={projectLabelFn}
          displaySimilarNames={hasMatchingPeople}
          onClose={() => setSelectModalOpen(false)}
        />
      )}
      {disconnectModalOpen && (
        <DisconnectModal
          name={label}
          onClose={() => setDisconnectModalOpen(false)}
          removeAndClose={(archiveData) => {
            trackIntegrationEvent(
              type,
              'Integration disconnect',
              {
                archiveData,
              },
              user.cid,
            );
            removeCoInt(coInt.integrations_co_id, archiveData);
            exit();
          }}
        />
      )}
    </Body>
  );
}

export default Setup;
