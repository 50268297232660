import React, { useMemo, useRef, useState } from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import DayDots from '@float/common/components/DayDots';
import DayTooltip from '@float/common/components/DayTooltip';
import LockIndicator from '@float/common/components/LockIndicator';
import { areEqualExtractStyle } from '@float/common/components/Schedule/util/diff';
import { displayHoursFormatIsTime } from '@float/common/lib/timer/displayHoursFormat';
import { formatDecimalHoursAsClockTime } from '@float/common/lib/timer/formatDecimalHoursAsClockTime';
import { getFilteredEntities } from '@float/common/search/selectors/filteredEntities';
import { getUser } from '@float/common/selectors/currentUser';
import { getLockPeriodDates } from '@float/common/selectors/lockLoggedTime';
import { getActiveFilters } from '@float/common/selectors/views';
import { useScheduleContext } from '@float/common/serena/ScheduleContext';
import { getLoggedTimeHours } from '@float/common/serena/util/getLoggedTimeHours';
import { useAppSelectorStrict } from '@float/common/store';
import { todayManager } from '@float/libs/dates';
import {
  AccountType,
  AnyEntity,
  LoggedTimeCell,
  PersonCell,
  TopCell,
} from '@float/types';
import { Tooltip } from '@float/ui/components/Tooltip';
import EH from '@float/ui/deprecated/Earhart';

import { ScheduleActions } from '../MainCell/types';
import { TopBar } from './TopBar';

import * as styles from './styles.css';

type DateViewType = 'daily' | 'weekly' | 'monthly';

type InnerComponentWithTooltipProps = {
  dates: DatesManager;
  dayWidth: number;
  suvPersonCell?: PersonCell | LoggedTimeCell;
  actions: ScheduleActions;
  disableTooltips: boolean;
  logTimeView: boolean;
  logMyTimeView: boolean;
  date: string;
  highlights: Record<number, AnyEntity[]>;
  idx: number;
};

function InnerComponentWithTooltip({
  dates,
  dayWidth,
  suvPersonCell,
  actions,
  disableTooltips,
  logTimeView,
  logMyTimeView,
  date,
  highlights,
  idx,
}: InnerComponentWithTooltipProps) {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const tooltipContent = (
    <DayTooltip
      highlights={highlights[idx]}
      onMilestoneClick={actions.showEditMilestoneModal}
      closeTooltip={() => setTooltipOpen(false)}
    />
  );

  const isMember = useAppSelectorStrict(
    (state) => state.currentUser.account_type_id === AccountType.Member,
  );

  function onDayClick(e: React.MouseEvent) {
    if (logMyTimeView) return;
    if (isMember) return;

    actions.showEditMilestoneModal(
      {
        name: '',
        date,
        end_date: date,
      },
      {
        allowProjectSelection: true,
        allowPhaseSelection: true,
        event: e,
      },
    );
  }

  const dayStr = dates.toDayString(date);
  const dayNum = dates.extractDay(date);

  const dayDots = (
    <DayDots
      highlights={highlights[idx]}
      dayWidth={dayWidth}
      logMyTimeView={logMyTimeView}
    />
  );

  const interactiveContent =
    tooltipContent && !disableTooltips ? (
      <Tooltip
        distance={0}
        delay={250}
        content={tooltipContent}
        placement="bottom"
        className="interactive"
        key={date}
        open={tooltipOpen}
        onOpenChange={setTooltipOpen}
      >
        {dayDots}
      </Tooltip>
    ) : (
      dayDots
    );

  const isMonthsView = dayWidth === 27;
  const isWeeksView = dayWidth === 90;
  const isDaysView = dayWidth === 245;

  let dateViewType: DateViewType = 'daily';

  if (isMonthsView) dateViewType = 'monthly';
  else if (isWeeksView) dateViewType = 'weekly';

  const loggedTimeHours = useAppSelectorStrict(
    (state) =>
      getLoggedTimeHours(
        suvPersonCell?.items,
        {
          filteredEntities: getFilteredEntities(state),
          filters: getActiveFilters(state),
        },
        idx,
      ).logged || 0,
  );

  const loggedHoursValue = useAppSelectorStrict((state) => {
    return displayHoursFormatIsTime(getUser(state).prefs)
      ? formatDecimalHoursAsClockTime(loggedTimeHours)
      : `${loggedTimeHours}h`;
  });

  const { latest: lockPeriodLatest, next: lockPeriodNext } =
    useAppSelectorStrict(getLockPeriodDates);

  const lockIndicator = logTimeView && (
    <LockIndicator
      className={
        isMonthsView
          ? styles.lockIndicator.monthly
          : styles.lockIndicator.default
      }
      currentDate={date}
      lockPeriodLatest={lockPeriodLatest}
      lockPeriodNext={lockPeriodNext}
      alternateRendering={isMonthsView && dayNum}
    />
  );

  const inner = (
    <div
      className={styles.dayCell}
      data-date-view-type={dateViewType}
      data-is-today={todayManager.isToday(date) || undefined}
      key={date}
      style={assignInlineVars({
        [styles.dayWidthVar]: `${dayWidth}px`,
      })}
      onClick={onDayClick}
    >
      {!logMyTimeView && isMonthsView && (logTimeView ? lockIndicator : dayNum)}
      {!logMyTimeView && isWeeksView && (
        <>
          <span className={styles.dayName}>{dayStr}</span>&nbsp;
          <span className={styles.dayNum}>{dayNum}</span>
        </>
      )}
      {(logMyTimeView || isDaysView) && (
        <>
          <span className={styles.dayName}>{dayStr}</span>&nbsp;
          <span className={styles.dayNum}>{dayNum}</span>
        </>
      )}
      <div className={styles.dotsWrapper}>{interactiveContent}</div>
      {!isMonthsView && lockIndicator}
      {logMyTimeView && (
        <div className={styles.logTimeContainer}>
          <span
            className={styles.logTimeLabel}
            data-has-loggedtime={loggedTimeHours > 0}
          >
            {loggedTimeHours > 0 && <EH.Icons.IconLogged size={15} />}
            {loggedHoursValue}
          </span>
        </div>
      )}
    </div>
  );

  return inner;
}

type TopCellInnerProps = {
  actions: ScheduleActions;
  cell: TopCell;
  colIdx: number;
  dateRange: string[];
  dates: DatesManager;
  dayWidth: number;
  disableTooltips: boolean;
  logMyTimeView: boolean;
  logTimeView: boolean;
  suvPersonCell: PersonCell | LoggedTimeCell | undefined;
  totalHeight: number;
  wrapperRef: React.RefObject<HTMLDivElement>;
};

const TopCellInner = React.memo(function TopCellInner(
  props: TopCellInnerProps,
) {
  const {
    dateRange,
    dayWidth,
    totalHeight,
    colIdx,
    cell,
    actions,
    wrapperRef,
  } = props;

  const { highlights = [] } = cell;

  return (
    <>
      <TopBar
        cell={cell}
        dayWidth={dayWidth}
        totalHeight={totalHeight}
        dateRange={dateRange}
        colIdx={colIdx}
        actions={actions}
        wrapperRef={wrapperRef}
      />
      <div className={styles.gridBackground}>
        {dateRange.map((date, idx) => {
          return (
            <InnerComponentWithTooltip
              key={date}
              {...props}
              date={date}
              highlights={highlights}
              idx={idx}
            />
          );
        })}
      </div>
    </>
  );
});

export type TopCellProps = {
  style: { width: number; left?: number };
  colIdx: number;
  dayWidth: number;
  totalHeight: number;
  cell: TopCell;
  suvPersonCell: PersonCell | LoggedTimeCell | undefined;
  actions: ScheduleActions;
  disableTooltips: boolean;
  rowGroupTop: number;
  rowIdx: number;
};

function TopCellComponent({
  style,
  colIdx,
  dayWidth,
  totalHeight,
  cell,
  suvPersonCell,
  actions,
  disableTooltips,
  rowGroupTop,
  rowIdx,
}: TopCellProps) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const {
    dates,
    logTimeView,
    logMyTimeView,
    scrollWrapperRef,
    availableWidth,
  } = useScheduleContext();
  const numDays = style.width / dayWidth;
  let width = style.width;

  const dateRange = useMemo(() => {
    const start = colIdx * 7;
    const end = (colIdx + 1) * 7;

    dates.ensureRange(colIdx, colIdx + 1);

    return dates.extractVisibleRange(dates.fromNum(start), dates.fromNum(end));
  }, [dates, colIdx]);

  if (logMyTimeView) {
    width = dayWidth === 140 ? numDays * dayWidth : availableWidth;
  }

  return (
    <div
      className={styles.wrapper}
      ref={wrapperRef}
      style={{
        ...style,
        width,
      }}
      onMouseDown={
        logMyTimeView
          ? undefined
          : (e) => {
              // 89, because time range interactions are only registered in the lower half of the TopCell
              if (e.button !== 0) return; // || (e.clientY && e.clientY < 89)) return;

              const target = e.target as HTMLElement;

              if (target.tagName === 'BUTTON' || target.tagName === 'INPUT') {
                return;
              }
              actions.onTimeRangeCellMouseDown();
            }
      }
      onMouseMove={
        logMyTimeView
          ? undefined
          : (e) => {
              if (!scrollWrapperRef.current) return;
              if (!wrapperRef.current) return;

              const { clientX, clientY } = e;
              const { offsetLeft, offsetParent } = wrapperRef.current;

              if (!offsetParent) return;

              const cellY =
                clientY -
                scrollWrapperRef.current.offsetTop -
                (rowGroupTop - scrollWrapperRef.current.scrollTop);

              const rawY =
                // @ts-expect-error Getting this from rowGroup (see useWindow.js)
                Number(wrapperRef.current.offsetParent?.dataset.translateY) +
                cellY;

              actions.trackMouse(
                colIdx,
                rowIdx,
                clientX - offsetLeft + scrollWrapperRef.current.scrollLeft,
                clientY - (offsetParent as HTMLElement).offsetTop,
                cellY,
                offsetLeft,
                rawY,
                clientX,
                clientY,
              );
            }
      }
    >
      <TopCellInner
        dateRange={dateRange}
        dayWidth={dayWidth}
        totalHeight={totalHeight}
        dates={dates}
        colIdx={colIdx}
        cell={cell}
        suvPersonCell={suvPersonCell}
        actions={actions}
        disableTooltips={disableTooltips}
        logTimeView={logTimeView}
        logMyTimeView={logMyTimeView}
        wrapperRef={wrapperRef}
      />
    </div>
  );
}

const ReduxDataRestrictor = React.memo(TopCellComponent, areEqualExtractStyle);

export default ReduxDataRestrictor;
