import { useSelector } from 'react-redux';

import { ReduxStateStrict } from '@float/common/reducers/lib/types';
import { FeatureFlag, featureFlags } from '@float/libs/featureFlags';

export const useProjectCodesPreference = () => {
  const isProjectCodesFeatureFlagEnabled = featureFlags.isFeatureEnabled(
    FeatureFlag.ProjectCodes,
  );
  const isProjectCodesCompanyPrefEnabled = useSelector(
    (state: ReduxStateStrict) => Boolean(state.companyPrefs.project_codes),
  );
  return {
    isProjectCodesEnabled: Boolean(
      isProjectCodesFeatureFlagEnabled && isProjectCodesCompanyPrefEnabled,
    ),
    isProjectCodesFeatureFlagEnabled,
  };
};
