import React from 'react';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import JoinComponents from '@float/common/components/elements/JoinComponents';
import { toFriendlyTime } from '@float/common/lib/time';
import { getPhaseById } from '@float/common/selectors/phases';
import { selectEnhancedProjectById } from '@float/common/selectors/projects/selectEnhancedProjectById';
import { useAppSelectorStrict } from '@float/common/store';
import { useIsDebugTaskNamesEnabled } from '@float/libs/hooks/useIsDebugTaskNamesEnabled';
import { CompanyPreferences, Phase } from '@float/types';
import { Icons } from '@float/ui/deprecated';

import { isArchived } from '../utils/isArchived';
import { addStartTimeElement } from './addStartTimeElement';
import { MainCellItem } from './types';

import {
  firstTextElement,
  iconGroupWidthVar,
  otherTextElement,
} from '../../EntityItemStyles.css';

interface TextElementsProps {
  entity: MainCellItem['entity'];
  numRows: number;
  iconGroupWidth: number;
  companyPrefs: CompanyPreferences;
  logTimeView: boolean | undefined;
  color?: string;
  isTimer?: boolean;
}

export const TextElements: React.FC<TextElementsProps> = ({
  entity,
  numRows,
  iconGroupWidth,
  companyPrefs,
  logTimeView,
  color,
  isTimer,
}) => {
  const isDebugTaskNamesEnabled = useIsDebugTaskNamesEnabled();

  const integrationStatus =
    'integration_status' in entity ? entity.integration_status : 0;

  const syncElement =
    integrationStatus === 1 ? (
      <Icons.SyncWhite
        color={color}
        style={{
          verticalAlign: -2,
        }}
      />
    ) : null;

  const timeOffType = useAppSelectorStrict((state) => {
    if ('timeoff_id' in entity && entity.timeoff_id) {
      return state.timeoffTypes.timeoffTypes[entity.timeoff_type_id];
    }
    return undefined;
  });

  const projectId = 'project_id' in entity ? entity.project_id : undefined;
  const phaseId = 'phase_id' in entity ? entity.phase_id : undefined;
  const project = useAppSelectorStrict((state) =>
    selectEnhancedProjectById(state, projectId as number),
  );

  const phase: Phase | undefined = useAppSelectorStrict(
    (state) => getPhaseById(state, phaseId as number) ?? undefined,
  );

  if (timeOffType && 'timeoff_id' in entity) {
    return (
      <div
        key="0"
        className={firstTextElement}
        style={assignInlineVars({ [iconGroupWidthVar]: `${iconGroupWidth}px` })}
      >
        {entity.start_time &&
          !isTimer &&
          `${toFriendlyTime(entity.start_time, companyPrefs)} - `}
        {timeOffType && timeOffType.timeoff_type_name}
        {timeOffType && !timeOffType.active ? ' (Archived)' : ''}
      </div>
    );
  }

  let clientElement =
    project && project.client_name !== 'No Client' ? project.client_name : null;

  if (isArchived(project, phase)) {
    if (clientElement) {
      clientElement += ' (Archived)';
    } else {
      clientElement = '(Archived)';
    }
  }

  const elements = [];

  let entityName = 'name' in entity ? entity.name : undefined;

  if ('logged_time_id' in entity && entity.task_name) {
    entityName = entity.task_name;
  }

  if (isDebugTaskNamesEnabled) {
    // @ts-expect-error it's ok to access and undefined property here
    // it's just for debug
    const id = entity.task_id || entity.timeoff_id;

    entityName = `[${id}] ${entityName}`;
  }

  const entityCalendarName =
    'ext_calendar_event_name' in entity
      ? entity.ext_calendar_event_name
      : undefined;

  const hasName = entityName || entityCalendarName;
  if (hasName) {
    if (syncElement) {
      elements.push(
        <>
          {entityCalendarName} {syncElement} {entityName}
        </>,
      );
    } else {
      elements.push(entityName);
    }
  } else {
    if (phase && phase.phase_name) {
      elements.push(phase.phase_name);
    }
  }

  if (project && project.project_name) {
    if (!hasName && syncElement) {
      elements.push(
        <>
          {syncElement} {project.project_name}
        </>,
      );
    } else {
      elements.push(project.project_name);
    }
  }

  if (clientElement) elements.push(clientElement);
  if (!logTimeView && !isTimer)
    addStartTimeElement(entity, elements, companyPrefs);

  if (numRows <= 1) {
    return (
      <div
        className={firstTextElement}
        style={assignInlineVars({ [iconGroupWidthVar]: `${iconGroupWidth}px` })}
        key="0"
      >
        <JoinComponents components={elements} />
      </div>
    );
  }

  if (numRows === 2) {
    return (
      <>
        {elements.length > 0 && (
          <div
            key="0"
            className={firstTextElement}
            style={assignInlineVars({
              [iconGroupWidthVar]: `${iconGroupWidth}px`,
            })}
          >
            {elements[0]}
          </div>
        )}
        {elements.length > 1 && (
          <div className={otherTextElement} key="1">
            {elements.slice(1).join(' / ')}
          </div>
        )}
      </>
    );
  }

  return (
    <>
      {elements.map((e, idx) => {
        if (idx === 0)
          return (
            <div
              key="0"
              className={firstTextElement}
              style={assignInlineVars({
                [iconGroupWidthVar]: `${iconGroupWidth}px`,
              })}
            >
              {e}
            </div>
          );
        return (
          <div className={otherTextElement} key={idx}>
            {' '}
            {e}{' '}
          </div>
        );
      })}
    </>
  );
};
