import React from 'react';
import ProjectColor from 'manage/projects-v2/ProjectColor';
import { createSelector } from 'reselect';

import { projectEditable } from '@float/common/lib/rights';
import { ReduxState, ReduxStateStrict } from '@float/common/reducers/lib/types';
import { getUser } from '@float/common/selectors/currentUser';
import {
  getProjectsOptions as _getProjectsOptions,
  getUnlockedTaskListProjectsOptions as _getUnlockedTaskListProjectsOptions,
  getLastProject,
} from '@float/common/selectors/projects';
import { ProjectOptions } from '@float/types';
import { allowCalendarLockedProjects as _allowCalendarLockedProjects } from '@float/web/pmSidebar/reducers/selectors';

export * from '@float/common/selectors/projects';

const parseGroup = (group: ProjectOptions) => ({
  ...group,
  options: group.options.map((option) => {
    return {
      label: option.label,
      value: option.value,
      client_name: option.client_name,
      selectedLabel: option.selectedLabel,
      icon: <ProjectColor color={option.color} />,
    };
  }),
});

const getProjectsOptions = createSelector([_getProjectsOptions], (groups) =>
  groups.map(parseGroup),
);

const getUnlockedTaskListProjectsOptions = createSelector(
  [_getUnlockedTaskListProjectsOptions],
  (groups) => groups.map(parseGroup),
);

const getCalendarProjectsOptions = createSelector(
  [
    _allowCalendarLockedProjects,
    getProjectsOptions,
    getUnlockedTaskListProjectsOptions,
  ],
  (allowCalendarLockedProjects, projects, restrictedProjects) =>
    allowCalendarLockedProjects ? projects : restrictedProjects,
);

const getDefaultDropdownCalendarProject = createSelector(
  [
    getLastProject,
    (state: ReduxState) => state.projects.projects,
    getUser,
    _allowCalendarLockedProjects,
  ],
  (lastUpdatedProject, projects, user, allowCalendarLockedProjects) => {
    if (lastUpdatedProject && lastUpdatedProject.project_id) {
      const project = projects[lastUpdatedProject.project_id];
      if (
        project &&
        project.active &&
        (allowCalendarLockedProjects || !project.locked_task_list) &&
        projectEditable(project, user)
      ) {
        return lastUpdatedProject;
      }
    }

    for (const [id, project] of Object.entries(projects)) {
      if (
        projectEditable(project, user) &&
        (allowCalendarLockedProjects || !project.locked_task_list) &&
        project.active
      ) {
        return { project_id: id };
      }
    }

    return {};
  },
);

const selectAllProjectCodes = createSelector(
  [(state: ReduxStateStrict) => state.projects.projects],
  (projects) =>
    Object.values(projects)
      .filter((project) => Boolean(project.project_code))
      .reduce(
        (codes, project) => codes.add(project.project_code!),
        new Set<string>(),
      ),
);

/* eslint import/export: 0 */
export {
  getProjectsOptions,
  getUnlockedTaskListProjectsOptions,
  getCalendarProjectsOptions,
  getDefaultDropdownCalendarProject,
  selectAllProjectCodes,
};
/* eslint no-use-before-define: 2 */
