import React from 'react';
import { t } from '@lingui/macro';

import { Rights } from '@float/common/lib/acl';
import { Account, CurrentUser } from '@float/types/account';
import { Department } from '@float/types/department';
import { IconArrowDownRight } from '@float/ui/deprecated/Earhart/Icons';
import VirtualSelect from '@float/ui/deprecated/VirtualSelect/VirtualSelect';
import { VirtualSelectOption } from '@float/ui/deprecated/VirtualSelect/VirtualSelect.types';
import { useWebAppSelector } from '@float/web/lib/store';
import { getSubdepartments, getUser } from '@float/web/selectors';

type Props = {
  departmentOptions: VirtualSelectOption[];
  departments: Record<string, Department>;
  form: { department_id?: Department['department_id']; account: Account };
  formErrors?: { department_id: string[] };
  isLimitedByDepartment?: boolean;
  onUpdate: (state: State) => void;
  placeholder?: string;
};

type State = {
  account?: Account;
  department_id: Department['department_id'] | string;
};

const useOptionsWithSubDepIcon = (
  departmentOptions: VirtualSelectOption[],
  departments: Record<string, Department>,
) => {
  return departmentOptions.map((o) => {
    const department = departments[o.value!];

    return {
      ...o,
      icon: department.parent_id && <IconArrowDownRight size={16} />,
    };
  });
};

/**
 * PersonDepartment
 *
 * @todo Eliminate app state (Redux) dependencies (user and subDepartments) to
 * adhere to the convention that components within the 'components' folder
 * should remain independent of app-specific business state.
 */

export const PersonDepartment = (props: Props) => {
  const {
    departmentOptions,
    departments,
    form,
    formErrors,
    isLimitedByDepartment = false,
    onUpdate,
    placeholder,
  } = props;

  const user: CurrentUser = useWebAppSelector(getUser);
  const subDepartments = useWebAppSelector(getSubdepartments);

  const options = useOptionsWithSubDepIcon(departmentOptions, departments);

  const canCreateDepartment = Rights.canCreateDepartment(user);

  const onSelectChange = (option: VirtualSelectOption) => {
    const { isCreate } = option;
    const nextDepartmentId = isCreate ? option.value : +option.value!;
    const newState: State = { department_id: nextDepartmentId || 0 };
    const { department_id, account } = form;
    if (newState.department_id === department_id) {
      return;
    }

    // make sure selected department is a part of viewable departments
    // unless a parent department is already in the list
    if (
      !isCreate &&
      nextDepartmentId &&
      account &&
      'department_filter_set' in account &&
      account.department_filter_set
    ) {
      const currentDepartmentFilter = account?.department_filter || [];
      const canViewNextDepartment = currentDepartmentFilter.some(
        (departmentId) => {
          return subDepartments[departmentId].includes(
            Number(nextDepartmentId),
          );
        },
      );

      if (!canViewNextDepartment) {
        newState.account = {
          ...account,
          department_filter: [
            ...currentDepartmentFilter.filter(
              (depId) =>
                depId !== nextDepartmentId &&
                !subDepartments[Number(nextDepartmentId)].includes(depId),
            ),
            Number(nextDepartmentId),
          ],
        };
      }
    }

    onUpdate(newState);
  };

  return (
    <VirtualSelect
      // @ts-expect-error VirtualSelect does not have types
      clearInputOnDropdownOpen={false}
      creatable={canCreateDepartment}
      errors={formErrors?.department_id}
      hideClearIcon={isLimitedByDepartment}
      label={t`Department`}
      nonNullable={isLimitedByDepartment}
      onChange={onSelectChange}
      options={options}
      placeholder={placeholder || t`No department`}
      value={form.department_id}
      visibleItems={6}
    />
  );
};
