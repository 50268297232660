import {
  BudgetPriority,
  BudgetType,
  Project,
  ProjectStatus,
  ProjectTeamMemberData,
  ProjectTemplate,
  RawProject,
  TemplateMilestone,
  TemplatePhase,
} from '@float/types';

import { PhaseApiPayload } from '../phases';
import {
  PROJECTS_BULK_UPDATED,
  PROJECTS_LOAD_FAILED,
  PROJECTS_LOAD_FINISH,
  PROJECTS_LOAD_START,
} from './actionTypes';

type NewMilestone = {
  name: string;
  date: string;
  end_date?: string;
};

type NewTask = {
  task_name: string;
  billable: 0 | 1;
  budget?: number | null;
};

type NewPhase = Partial<PhaseApiPayload> & {
  milestones?: NewMilestone[];
  tasks?: NewTask[];
};

export type ProjectTeamPayload = {
  sub?: Record<number, number>;
  add?: Record<number, number>;
  del?: Record<number, number>;
};

export enum RateType {
  PerPerson = 1,
  PerProject = 2,
}

export type ProjectApiPayload = {
  client_id: number | null | undefined;
  color: string;
  project_manager: number;
  name: string;
  notes: string;
  active: 0 | 1;
  all_pms_schedule: number;
  non_billable: number;
  status: ProjectStatus;
  /**
   * @deprecated Use `status`. Remove `tentative` field from payload after API
   * adds support for `status` field.
   */
  tentative: number;
  locked_task_list: number;
  default_hourly_rate: number | null;
  budget_type: number | undefined;
  budget_total: number | null;
  budget_priority: BudgetPriority;
  tags: string[];
  notes_meta: RawProject['notes_meta'];
  start_date?: string | null;
  end_date?: string | null;
  project_team?: {
    set?: ProjectTeamMemberData[];
    add?: ProjectTeamMemberData[];
    del?: number[];
  } | null;
  project_code?: string;
};

export type AsyncProjectApiPayload = Partial<ProjectApiPayload> & {
  project_team?: ProjectTeamMemberData[];
  milestones?: NewMilestone[];
  tasks?: NewTask[];
  phases: NewPhase[];
  rate_type?: RateType;
};

export type ProjectCreateOptions = {
  fromTemplate?: ProjectTemplate['project_template_id'];
};

export type ProjectsLoadStartAction = {
  type: typeof PROJECTS_LOAD_START;
};

export type ProjectsLoadFailedAction = {
  type: typeof PROJECTS_LOAD_FAILED;
};

export type ProjectsLoadFinishAction = {
  type: typeof PROJECTS_LOAD_FINISH;
  projects: RawProject[] | null;
  forceLoad: boolean;
  archivedProjects?: RawProject[] | null;
  includeArchived?: boolean;
};

export type ProjectsBulkUpdatedAction = {
  type: typeof PROJECTS_BULK_UPDATED;
  ids: number[];
  fields: {
    tags: {
      add?: string[];
      del?: string[];
    };
    creator_id: number;
    budget: number;
  } & Project;
};

export type ProjectInputData = {
  active: boolean;
  client_id: number | string | undefined;
  common: boolean;
  color: string;
  description: string;
  locked_task_list: 0 | 1;
  non_billable: boolean;
  notes_meta: Project['notes_meta'];
  project_manager: number | undefined;
  project_name: string;
  project_code?: string;
  tags: string[];
  budget_type: BudgetType | undefined;
  budget_total: number | null;
  budget_priority?: BudgetPriority;
  default_hourly_rate: number | null;
  status: ProjectStatus;
  start_date?: string | null;
  end_date?: string | null;
  duration?: number | null;
};

export type TemplateInputData = ProjectInputData & {
  team_people?: { id: number; hourly_rate?: number }[];
  task_metas?: { task_meta_id: number; task_name: string; billable: 0 | 1 }[];
  milestones?: TemplateMilestone[];
  phases?: TemplatePhase[];
};
